<template>
  <mdb-modal size="lg" @close="setShowModalItemCreate({show: false})">
    <mdb-modal-header>
      <mdb-modal-title>Nuovo elemento</mdb-modal-title>
    </mdb-modal-header>
    <form @submit="submitForm">
        <mdb-modal-body>
        <mdb-row>
            <mdb-col lg="6" md="12">
            <mdb-row>
                <mdb-col col="8">
                <label for="model_add_food_input_image" class="grey-text">Immagine</label>
                <input
                    type="file"
                    accept="image/*"
                    @change="form.image = $event.target.files[0]"
                    id="model_add_food_input_image"
                    class="form-control"
                /><br />
                <mdb-btn size="sm" outline="danger" v-if="form.image_id" @click.native="$parent.deleteFoodImage($event, form.food_id)"><i class="fas fa-trash-alt"></i> Elimina immagine</mdb-btn>
                </mdb-col>
                <mdb-col col="4" class="text-center p-0">
                <img v-if="form.image_id" :src="$static_hostname + '/images/' + form.image_id" style="max-width:100%;max-height:140px" />
                </mdb-col>
            </mdb-row>
            <br />
            <label for="model_add_food_input_name" class="grey-text">Categoria</label>
            <select v-model="form.category_id" class="form-control" required>
                <option></option>
                <option
                v-for="category in getMenu(menu_id).categories"
                v-bind:key="category._id"
                v-bind:value="category._id">
                {{ category.name['it_it'] }}
                </option>
            </select>
            <br />
            <label for="model_add_food_input_name" class="grey-text">Nome</label>
            <input
                type="text"
                id="model_add_food_input_name"
                class="form-control"
                v-model="form.name" />
            <br />
            <label for="model_add_food_input_description" class="grey-text">Descrizione</label>
            <textarea
                id="model_add_food_input_description"
                class="form-control"
                v-model="form.description"
            ></textarea>
            <br/>
            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="model_add_food_category_input_show"
                v-model="form.show">
                <label class="custom-control-label" for="model_add_food_category_input_show">Mostra elemento nel menu pubblico</label>
            </div>
            </mdb-col>
            <mdb-col lg="6" md="12">
            <hr class="d-block d-lg-none" />
            <h4>Prezzi</h4>
            <div v-for="(food_option, i) in form.prices" :key="'modal_edit_food_option_add_'+i">
                <hr />
                <div class="row">
                <div class="col">
                    <label :for="'model_edit_food_input_option_add_food_option_name_'+i" class="grey-text">Descrizione</label>
                    <mdb-input type="text" :id="'model_edit_food_input_option_add_food_option_name_'+i" basic class="mb-3" v-model="form.prices[i].name">
                    </mdb-input>
                </div>
                <div class="col">
                    <label :for="'model_edit_food_input_option_add_price_'+i" class="grey-text">Prezzo</label>
                    <mdb-input type="number" :id="'model_edit_food_input_option_add_price_'+i" basic class="mb-3" v-model="form.prices[i].price">
                    <span class="input-group-text" slot="prepend">€</span>
                    </mdb-input>
                </div>
                </div>
                <div class="row">
                <div class="col">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" :id="'model_edit_food_input_option_add_available_'+i"
                        v-model="form.prices[i].available">
                        <label class="custom-control-label" :for="'model_edit_food_input_option_add_available_'+i">Disponibile</label>
                    </div>
                </div>
                <div class="col">
                    <mdb-btn size="sm" outline="danger" @click="form.prices.splice(i, 1)"><i class="fas fa-trash-alt"></i> Elimina prezzo</mdb-btn>
                </div>
                </div>
            </div>
            <div class="text-center">
                <button type="button" class="btn btn-floating"
                    @click="form.prices.push({'name':'', 'available':true, 'price':''})">
                    <i class="fas fa-plus"></i> Aggiungi prezzo
                </button>
            </div>
            </mdb-col>
        </mdb-row>
        </mdb-modal-body>
        <mdb-modal-footer>
        <mdb-btn color="none" @click="setShowModalItemCreate({show: false})">Annulla</mdb-btn>
        <mdb-btn color="primary" type="submit" :disabled="loading">
            <span class="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true" v-if="loading"></span>
            Aggiungi
        </mdb-btn>
        </mdb-modal-footer>
    </form>
  </mdb-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mdbRow, mdbCol, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbInput, mdbBtn } from 'mdbvue';
export default {
    name: "ModalItemAdd",
    props: ['menu_id'],
  components: {
    mdbRow, mdbCol,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbInput,
    mdbBtn
  },
  data() {
      return {
          loading: false,
          form: {
              image: null,
              image_id: null,
              name: '',
              show: true,
              description: '',
              category_id: '',
              prices: []
          }
      }
  },
  created () {
      this.form.category_id = this.getCategoryOfModalItemCreate
  },
  computed: mapGetters(['getMenu', 'getCategoryOfModalItemCreate']),
  methods: {
      ...mapActions(['setShowModalItemCreate', 'addItem']),
      submitForm: function(e) {
          e.preventDefault();
          this.loading = true;
          this.addItem({
              menu_id: this.menu_id,
              item: {...this.form}
          }).then(res => {
              this.loading = false;
              if (res.status == 200) {
                  this.setShowModalItemCreate({show: false});
              }
          }).catch(err => {
              this.loading = false;
              alert(err);
          })
      }
  }
};
</script>