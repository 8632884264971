<template>
  <div>
    <section id="menu" class="m-auto" style="max-width: 1500px">
      <mdb-card>
        <mdb-card-header v-if="menu">
          <span class="text-truncate">
            <template v-if="menu">{{ menu.menu_name }}</template>
          </span>
        </mdb-card-header>
        <mdb-card-body class="text-center" v-if="ajax_page_loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </mdb-card-body>
        <mdb-card-body class="text-center" v-else-if="!menu">
          <mdb-alert color="danger" v-if="ajax_page_error">
            Impossibile caricare la pagina richiesta. Si prega di verificare che la connessione a Internet funzioni correttamente e riaggiornare la pagina. Verificare inoltre che il link sia corretto. Se il problema persiste si prega di contattarci.
          </mdb-alert>
          <mdb-alert color="danger" v-else>
            Impossibile caricare la pagina richiesta. Si prega di verificare che la connessione a Internet funzioni correttamente e riaggiornare la pagina. Se il problema persiste si prega di contattarci.
          </mdb-alert>
        </mdb-card-body>
        <mdb-card-body v-else>
          <div class="text-center mb-4" v-if="!ajax_page_error && !ajax_page_loading">
            <button type="button" class="btn"
              @click="setShowModalMenuQRCode(true)">
              <i class="fas fa-qrcode"></i> Link e codice QR
            </button>
            <a :href="$public_menu_hostname + '/' + menu.menu_short_url" target="_blank" class="btn">
                <i class="fas fa-external-link-alt"></i> Apri menu pubblico
            </a>
            <a :href="$static_hostname + '/pdf/' + menu.menu_short_url" target="_blank" class="btn">
                <i class="fas fa-file-pdf"></i> Versione PDF
            </a>
          </div>





          <nav class="tabbable">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a class="nav-link" @click="currentTab = 'menu'" :class="(currentTab == 'menu') ? 'active' : ''">Menu</a>
              <a class="nav-link" @click="currentTab = 'generalSettings'" :class="(currentTab == 'generalSettings') ? 'active' : ''">Impostazioni</a>
              <a class="nav-link" @click="currentTab = 'items'" :class="(currentTab == 'items') ? 'active' : ''">Elementi</a>
            </div>
          </nav>

          <div class="container card card-body" v-if="currentTab == 'generalSettings'">
            <MenuGeneralSettings :menu_id="menu.menu_id" />
          </div>

          <div class="table-responsive" v-show="currentTab == 'menu'" v-if="menu">
            <div class="text-center">
              <button type="button" class="btn btn-primary"
                @click="setShowModalCategoryAdd(true)">
                <i class="fas fa-plus"></i> Nuova categoria
              </button>
              <button type="button" class="btn btn-primary"
                @click="setShowModalItemCreate({show: true})"
                v-if="menu && menu.menu.sub_categories.length > 0">
                <i class="fas fa-plus"></i> Nuovo elemento
              </button>
            </div>
            <br />
            <mdb-tbl v-if="menu.menu.sub_categories.length > 0">
              <thead>
                <tr>
                  <th style="width:0">Riordina</th>
                  <th style="width:0">Immagine</th>
                  <th>Nome</th>
                  <th style="width:100px">Prezzo</th>
                  <th style="width:100px">Disponibile</th>
                  <th style="width:0">Azioni</th>
                </tr>
              </thead>
              <MenuCategory
                v-for="(food_category_id, food_category_sort_position) in menu.menu.sub_categories"
                :key="'menu-category-' + food_category_id"

                :menu_id="menu.menu_id"
                :category_id="food_category_id"
                :category_sorting_position="food_category_sort_position">
              </MenuCategory>
            </mdb-tbl>
          </div>
          
          <div class="container card card-body" v-if="currentTab == 'items'">
            <MenuItems :menu_id="menu.menu_id" />
          </div>
          
          <!-- <div class="text-center" v-if="menu && menu.menu.sub_categories.length > 0">
            <button type="button" class="btn"
              @click="enableAllFoodOptions()">
              Imposta tutte le voci come disponibili
            </button>
          </div> -->
        </mdb-card-body>
      </mdb-card>
    </section>
    <div>
    <ModalCategoryAdd v-if="getShowModalCategoryAdd" :menu_id="menu.menu_id"></ModalCategoryAdd>
    <ModalCategoryEdit v-if="getShowModalCategoryEdit" :menu_id="menu.menu_id"></ModalCategoryEdit>
    <ModalCategoryDelete v-if="getShowModalCategoryDelete" :menu_id="menu.menu_id"></ModalCategoryDelete>

    <ModalItemAdd v-if="getShowModalItemCreate" :menu_id="menu.menu_id" />
    <ModalItemEdit v-if="getShowModalItemUpdate" :menu_id="menu.menu_id" />
    <ModalItemDelete v-if="getShowModalItemDelete" :menu_id="menu.menu_id" />

    <ModalMenuQRCode v-if="getShowModalMenuQRCode" :menu_id="menu.menu_id" />
    <ModalMenuEdit v-if="getShowModalMenuUpdate" />
    <ModalMenuDelete v-if="getShowModalMenuDelete" />
    </div>
    
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mdbCard, mdbCardBody, mdbCardHeader, mdbTbl, mdbAlert } from 'mdbvue'

import MenuCategory from '../components/MenuCategory.vue'
import ModalMenuQRCode from "../components/modals/menu/ModalMenuQRCode.vue";

import MenuGeneralSettings from "../components/MenuGeneralSettings";
import MenuItems from "../components/MenuItems";

import ModalMenuEdit from "../components/modals/menu/ModalMenuEdit.vue";
import ModalMenuDelete from "../components/modals/menu/ModalMenuDelete.vue";

import ModalCategoryAdd from '../components/modals/category/ModalCategoryAdd.vue'
import ModalCategoryEdit from '../components/modals/category/ModalCategoryEdit.vue'
import ModalCategoryDelete from '../components/modals/category/ModalCategoryDelete.vue'

import ModalItemAdd from '../components/modals/item/ModalItemAdd.vue'
import ModalItemEdit from '../components/modals/item/ModalItemEdit.vue'
import ModalItemDelete from '../components/modals/item/ModalItemDelete.vue'

export default {
  name: 'MenuMenu',
  components: {
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbTbl,
    mdbAlert,
    
    MenuCategory,

    MenuItems,
    MenuGeneralSettings,

    ModalMenuQRCode,
    ModalMenuEdit,
    ModalMenuDelete,

    ModalCategoryAdd,
    ModalCategoryEdit,
    ModalCategoryDelete,

    ModalItemAdd,
    ModalItemEdit,
    ModalItemDelete,
  },

  data () {
    return {
      ajax_page_error: false,
      ajax_page_loading: false,

      currentTab: 'menu',
      menu: null,
      menuForm: null,
    }
  },
  async created () {
    await this.updateView();
  },
  watch: {
    '$route' () {
      this.updateView();
    },
    'getMenus' () {
      this.menu = this.getMenuByUrl(this.$route.params.short_url)
    },
  },
  computed: mapGetters([
    'getShowModalMenuUpdate', 'getShowModalMenuDelete',
    'getMenuByUrl', 'getMenus',
    'getShowModalMenuQRCode',
    'getShowModalCategoryAdd', 'getShowModalCategoryEdit', 'getShowModalCategoryDelete',
    'getShowModalItemCreate', 'getShowModalItemUpdate', 'getShowModalItemDelete',
  ]),
  methods: {
    ...mapActions([
      'fetchUserToken',
      'fetchMenuByUrl',
      'setCurrentMenu',
      'setShowModalMenuUpdate', 'setModalMenuUpdateId',
      'setModalMenuDeleteId', 'setShowModalMenuDelete',
      'setShowModalCategoryAdd',
      'setShowModalItemCreate',
      'setShowModalMenuQRCode',
    ]),
    updateView: function() {
      this.fetchUserToken();
      this.ajax_page_loading = true;
      this.currentTab = 'menu';

      this.short_url = this.$route.params.short_url;

      this.menu = this.getMenuByUrl(this.$route.params.short_url)

      this.fetchMenuByUrl(this.$route.params.short_url).then(menu => {
        this.ajax_page_loading = false;
        this.setCurrentMenu(menu.menu_id);
        this.menu = this.getMenuByUrl(this.$route.params.short_url)
        this.menuForm = JSON.parse(JSON.stringify(this.menu))
      }).catch(() => {
        this.ajax_page_loading = false;
        this.ajax_page_error = true;
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card.card-cascade .view.gradient-card-header {
  padding: 1rem 1rem;
  text-align: center;
}
.card.card-cascade h3,
.card.card-cascade h4 {
  margin-bottom: 0;
}
</style>
