import Reservation from '@/modules/reservation/views/index'
import ReservationDashboard from '@/modules/reservation/views/ReservationDashboard'
import ReservationReservationForm from '@/modules/reservation/views/ReservationFormSettings'
import ReservationReservationForms from '@/modules/reservation/views/ReservationForms'

export default {
  path: 'reservation',
  name: 'Reservations',
  component: Reservation,
  children: [
    {
      path: '',
      name: 'ReservationDashboard',
      component: ReservationDashboard
    },
    {
      path: 'forms',
      name: 'ReservationReservationForms',
      component: ReservationReservationForms
    },
    {
      path: 'form/:reservation_form_url?',
      name: 'ReservationReservationForm',
      component: ReservationReservationForm
    }
  ]
}