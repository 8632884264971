<template>
    <mdb-modal @close="setShowModalItemDelete(false)">
      <mdb-modal-header>
        <mdb-modal-title>Vuoi eliminare questo elemento?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        Stai eliminando definitivamente l'elemento {{ getItem({ menu_id, item_id:getModalItemDeleteId }).name['it_it'] }}. Vuoi proseguire?
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="none" @click.native="setShowModalItemDelete(false)">No, non eliminare</mdb-btn>
        <mdb-btn color="danger" @click.native="confirmDelete" :disabled="loading">
            <span class="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true" v-if="loading"></span>
            Sì, elimina
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn } from 'mdbvue';
export default {
  props: ['menu_id'],
  name: "ModalItemDelete",
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn
  },
  data() {
      return {
          loading: false,
      }
  },
  computed: mapGetters(['getModalItemDeleteId', 'getItem']),
  methods: {
    ...mapActions(['setShowModalItemDelete', 'deleteItem']),
    confirmDelete: function() {
        this.loading = true;
        this.deleteItem({
            menu_id: this.menu_id,
            item_id: this.getModalItemDeleteId
        }).then(res => {
            this.loading = false;
            if (res.status == 200) {
                this.setShowModalItemDelete(false);
            }
        }).catch(err => {
            this.loading = false;
            alert(err);
        })
      }
  }
};
</script>