<template>
  <div>
    <button
      type="button"
      class="btn btn-danger"
      @click="show = true"
    >
      <i class="fas fa-trash-alt"></i>
    </button>
    <mdb-modal :show="show" @close="show = false">
      <mdb-modal-header>
        <mdb-modal-title>Vuoi eliminare questo elemento?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div class="alert alert-danger" v-if="error">Spiacenti, si è verificato un errore. Si prega di verificare la connessione a Internet e riprovare. Se il problema persiste, vi preghiamo di contattarci a <a href="mailto:info@easymenu.cloud">info@easymenu.cloud</a>.</div>
        Stai eliminando definitivamente il modulo di prenotazione {{ reservationForm.name }}. Vuoi proseguire?
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="none" @click="show = false">No, non eliminare</mdb-btn>
        <mdb-btn color="danger" @click="confirmDelete" :disabled="loading">
            <span class="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true" v-if="loading"></span>
            Sì, elimina
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn } from 'mdbvue';
export default {
  props: ['reservationForm'],
  name: "ReservationFormDeleteButton",
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn
  },
  data() {
      return {
          show: false,
          loading: false,
          error: false,
      }
  },
  methods: {
    ...mapActions(['deleteReservationForm']),
    confirmDelete: function() {
      this.loading = true;
      this.error = false;

      this
      .deleteReservationForm(this.reservationForm._id)
      .then(res => {
        this.loading = false;
        if (res.status == 200) {
          this.show = false;
        } else {
          this.error = true;
        }
      }).catch(err => {
        console.log(err);
        this.loading = false;
        this.error = true;
      })
    }
  }
};
</script>