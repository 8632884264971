<template>
	<section>
		<AuthenticationForgotPassword v-if="actionForgotPassword" />
		<mdb-row v-else class="justify-content-center m-auto">
			<mdb-col style="max-width: 400px">
				<mdb-card>
					<mdb-card-body>
						<form @submit="doLogin">
              <vue-recaptcha
								ref="recaptcha"
								sitekey="6LejVAgdAAAAAMRKFdzDZIB2JiwF6e0EssOp9qQX"
								size="invisible"
								:loadRecaptchaScript="true"
								@verify="onCaptchaVerified"
								@expired="resetCaptcha"
							></vue-recaptcha>
							<p class="h4 text-center">Login</p>
							<div class="alert alert-danger" v-if="showWrongCredentials">
								E-mail o password errata. Si prega di verificare le credenziali e riprovare.
							</div>
							<mdb-input
								label="Email"
								icon="envelope"
								group
								type="email"
								validate
								error="wrong"
								success="right"
								v-model="form.email"
								@change="showWrongCredentials = false; changeEmail()"
							/>
							<mdb-input
								label="Password"
								icon="lock"
								group
								type="password"
								validate
								ref="password"
								v-model="form.password"
								@change="showWrongCredentials = false"
							/>
							<div class="custom-control custom-checkbox">
									<input type="checkbox" class="custom-control-input" id="remember" v-model="form.remember">
									<label class="custom-control-label" for="remember">Ricorda la password su questo dispositivo</label>
							</div>
							<div class="text-center py-4 mt-3">
								<mdb-btn color="primary" type="submit" :disabled="loading">
									<span
										class="spinner-border spinner-border-sm mr-4"
										role="status"
										aria-hidden="true"
										v-if="loading"
									></span>
									Login
								</mdb-btn>
							</div>
							<div class="text-center py-4 mt-3">
								<mdb-btn color="white" @click="actionForgotPassword = true">
									Password dimenticata
								</mdb-btn>
							</div>
						</form>
					</mdb-card-body>
				</mdb-card>
			</mdb-col>
		</mdb-row>
	</section>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { mdbRow, mdbCol, mdbCard, mdbCardBody, mdbInput, mdbBtn } from "mdbvue";
import AuthenticationForgotPassword from "./AuthenticationForgotPassword.vue";
import VueRecaptcha from 'vue-recaptcha'

function validateEmail(email) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export default {
	name: "AuthenticationLogin",
	props: ["password"],
	components: {
    VueRecaptcha,
		mdbRow,
		mdbCol,
		mdbCard,
		mdbCardBody,
		mdbInput,
		mdbBtn,
		AuthenticationForgotPassword,
	},
	data() {
		return {
			loading: false,
			form: {
				email: "",
				password: "",
				remember: true,
			},
			showWrongCredentials: false,
			actionForgotPassword: false,
		};
	},
	computed: mapGetters(["getAuthEmail"]),
	created() {
		this.form.email = this.getAuthEmail;
		this.form.password = this.password;
		this.$nextTick(() => {
			this.$refs.password.focus();
		});
	},
	methods: {
		...mapActions(["setUserToken", "setAuthEmail"]),
		changeEmail: function () {
			this.setAuthEmail(this.form.email);
		},
		doLogin: function (event) {
			event.preventDefault();

			if (validateEmail(this.form.email)) {
				this.$refs.recaptcha.execute()
			} else {
				alert("Please insert a valid email address");
			}
		},
		onCaptchaVerified: function (token) {
			this.loading = true;
			this.resetCaptcha()
			axios
			.post(`${this.$api_hostname}/api/v1/auth`, {
				email: this.form.email,
				password: this.form.password,
				captcha_token: token
			})
			.then((response) => {
				this.loading = false;
				if (response.status == 200) {
					let expiration = (this.form.remember) ? "30d" : "";
					this.setUserToken({userToken: response.data.token, expiration});
					if (this.$route.query.redirect) {
						this.$router.push(this.$route.query.redirect);
					} else {
						this.$router.push("dashboard");
					}
				} else {
					alert("Error");
				}
			})
			.catch((err) => {
				this.loading = false;
				if (err.response.status === 401) {
					this.showWrongCredentials = true;
				} else {
					alert(
						"Errore. Si prega di controllare la connessione ad Internet e di provare ad aggiornare la pagina. Errore: " +
							err
					);
				}
			});
	
		},
		resetCaptcha() {
				this.$refs.recaptcha.reset()
		},
	},
};
</script>

<style scoped>
.profile-card-footer {
	background-color: #f7f7f7 !important;
	padding: 1.25rem;
}
.card.card-cascade .view {
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15), 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}
</style>
