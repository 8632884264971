<template>
    <mdb-modal @close="setShowModalMenuDelete(false)" v-if="getMenu(getModalMenuDeleteId)">
      <mdb-modal-header>
        <mdb-modal-title>Vuoi eliminare questo menu?</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        Stai eliminando definitivamente il menu <span class="font-weight-bold">{{ getMenu(getModalMenuDeleteId).menu_name }}</span>. Tutti gli elementi all'interno del menu verranno eliminati definitivamente. Vuoi proseguire?
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="none" @click.native="setShowModalMenuDelete(false)">No, non eliminare</mdb-btn>
        <mdb-btn color="danger" @click.native="confirmDelete" :disabled="loading">
            <span class="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true" v-if="loading"></span>
            Sì, elimina
        </mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn } from 'mdbvue';
export default {
  props: ['menu_id'],
  name: "ModalMenuDelete",
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn
  },
  data() {
      return {
          loading: false,
      }
  },
  computed: mapGetters(['getMenu', 'getModalMenuDeleteId']),
  methods: {
    ...mapActions(['setShowModalMenuDelete', 'deleteMenu']),
    confirmDelete: function() {
        this.loading = true;
        this.deleteMenu(this.getModalMenuDeleteId).then(res => {
            this.loading = false;
            if (res.status == 200) {
                this.setShowModalMenuDelete(false);
            }
        }).catch(err => {
            this.loading = false;
            alert(err);
        })
      }
  }
};
</script>