<template>
    <section id="authentication">
      <div class="text-center m-5"><a href="https://easymenu.cloud"><img src="@/assets/images/EasyMenu.cloud-header-white.svg" height="30" style="max-width:90%" /></a></div>

        <AuthenticationLogin v-if="action_login" :password="forms.check_email.password" />
        <AuthenticationRegistration v-if="action_register" />
        
        <mdb-row class="justify-content-center m-auto" v-if="!action_login && !action_register && !action_email_activation">
          <mdb-col style="max-width:400px">
            <mdb-card>
              <mdb-card-body>
                <form @submit="doCheckEmail">
                  <vue-recaptcha
                    ref="recaptcha"
                    sitekey="6LejVAgdAAAAAMRKFdzDZIB2JiwF6e0EssOp9qQX"
                    size="invisible"
                    :loadRecaptchaScript="true"
                    @verify="onCaptchaVerified"
                    @expired="resetCaptcha"
                  ></vue-recaptcha>
                  <div class="alert alert-warning" v-if="email_not_verified">L'email inserita non è stata ancora verificata. Si prega di cliccare il link ricevuto via email (controllare anche lo spam). In caso di problemi vi preghiamo di contattarci a <a href="mailto:info@easymenu.cloud">info@easymenu.cloud</a>.</div>
                  <div class="alert alert-info">Inserire l'email per procedere.</div>
                  <div class="grey-text">
                    <mdb-input
                      label="Email"
                      icon="envelope"
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                      name="email"
                      autocomplete="email"
                      ref="check_email_email"
                      autofocus
                      v-model="forms.check_email.email"
                    />
                    <input
                      class="d-none"
                      type="password"
                      name="password"
                      autocomplete="password"
                      v-model="forms.check_email.password"
                    />
                  </div>
                  <div class="text-center py-4 mt-3">                    
                    <mdb-btn color="primary" type="submit" :disabled="auth_email_form_next_loading">
                      <span
                        class="spinner-border spinner-border-sm mr-4"
                        role="status"
                        aria-hidden="true"
                        v-if="auth_email_form_next_loading"
                      ></span>
                      Avanti
                    </mdb-btn>
                  </div>
                </form>
              </mdb-card-body>
            </mdb-card>
          </mdb-col>
        </mdb-row>
    </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from 'axios';
import { mdbRow, mdbCol, mdbCard, mdbCardBody, mdbInput, mdbBtn } from 'mdbvue'
import VueCookies from 'vue-cookies'
import VueRecaptcha from 'vue-recaptcha'

import AuthenticationLogin from './AuthenticationLogin.vue'
import AuthenticationRegistration from './AuthenticationRegistration.vue'

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default {
  name: 'Authentication',
  components: {
    VueRecaptcha,
    AuthenticationLogin,
    AuthenticationRegistration,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbInput, mdbBtn
  },
  data () {
    return {
      forms: {
        check_email: {
          email: '',
          password: ''
        }
      },
      
      email: '',

      action_register: false,
      action_login: false,
      action_email_activation: false,

      email_not_verified: false,
      auth_email_form_next_loading: false,
    }
  },
  watch: {
    getAuthEmail: function () {
      this.action_login = false;
      this.action_register = false;
      this.$nextTick(() => {
        this.$refs.check_email_email.focus()
        this.forms.check_email.email = this.getAuthEmail;
      })
    }
  },
  computed: mapGetters(['getAuthEmail']),
  created () {
    this.show_auth_card = true;
    let token = VueCookies.get('token');
    if (token !== null) {
      this.$router.push('dashboard')
    }
  },
  methods: {
    ...mapActions(['setAuthEmail']),
    doCheckEmail: function(event) {
      event.preventDefault();

      if(validateEmail(this.forms.check_email.email)) {

				this.$refs.recaptcha.execute()

      } else {
        alert('Please insert a valid email address');
      }
    },
		onCaptchaVerified: function (token) {
      this.auth_email_form_next_loading = true;
			this.resetCaptcha()
      this.setAuthEmail(this.forms.check_email.email)

      axios.post(`${this.$api_hostname}/api/v1/auth/check_email`, {
        'email': this.forms.check_email.email,
        captcha_token: token
      })
      .then(response => {
          this.auth_email_form_next_loading = false;
          if (response.status == 200) {
              if(response.data.exists) {
                if(response.data.active) {
                  this.action_login = true;
                } else {
                  this.email_not_verified = true;
                }
              } else {
                this.action_register = true;
              }
          } else {
              alert('Error');
          }
      }).catch((err) => {
          this.auth_email_form_next_loading = false;
          alert('Errore. Si prega di controllare la connessione ad Internet e di provare ad aggiornare la pagina. ' + err);
      });
    },
		resetCaptcha() {
      this.$refs.recaptcha.reset()
		},
  }
}
</script>

<style scoped>
.profile-card-footer {
  background-color: #F7F7F7 !important;
  padding: 1.25rem;
}
.card.card-cascade .view {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15), 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}
</style>
