import Vue from 'vue';
import Router from 'vue-router';

import Dashboard from '@/views/Dashboard'

import Authentication from '@/modules/authentication/components/Authentication'
import EmailActivation from '@/modules/authentication/components/EmailActivation'
import PasswordReset from '@/modules/authentication/components/PasswordReset'

import menuRoutes from './menu/index'
import reservationRoutes from './reservation/index'

import BadGateway from '@/components/BadGateway'
import VueCookies from 'vue-cookies'


Vue.use(Router);

var router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/auth',
      name: 'Authentication',
      component: Authentication,
    },
    {
      path: '/auth/email_activation/:token',
      name: 'EmailActivation',
      component: EmailActivation,
    },
    {
      path: '/auth/password_reset/:token',
      name: 'PasswordReset',
      component: PasswordReset,
    },
    { path: '/dashboard', redirect: '/' },
    { path: '/', redirect: '/menu' },
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      children: [
        menuRoutes, // path: 'menu'
        reservationRoutes, // path: 'reservation;
      ]
    },

    {
      path: '/404',
      name: 'BadGateway',
      component: BadGateway
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})


router.beforeEach((to, _, next) => {
  const allowedRoutes = ['Authentication', 'EmailActivation', 'PasswordReset', 'BadGateway'];
  if (!allowedRoutes.includes(to.name) && !VueCookies.get('token')) next({ name: 'Authentication', query: {redirect: to.path} })
  else next()
})

export default router;