<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form @submit="submitForm">
          <div class="card-title clearfix sticky-top bg-white">
            <div class="float-left">{{ modalTitle }}</div>
            <div class="float-right">
              <mdb-btn type="submit" color="primary" :disabled="loading">
                  <span class="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true" v-if="loading"></span>
                  {{ submitButtonText }}
              </mdb-btn>
            </div>
          </div>

          <nav class="tabbable">
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a class="nav-link" @click="currentTab = 'general'" :class="(currentTab == 'general') ? 'active' : ''">Generali</a>
              <a class="nav-link" @click="currentTab = 'notifications'" :class="(currentTab == 'notifications') ? 'active' : ''">Notifiche</a>
              <a class="nav-link" @click="currentTab = 'reservations'" :class="(currentTab == 'reservations') ? 'active' : ''">Prenotazioni</a>
            </div>
          </nav>
          <div class="container card card-body" v-if="currentTab == 'general'">
            <mdb-row>
              <mdb-col md="6" sm="12">
                <label for="reservationName">Nome pubblico</label>
                <input
                  type="text"
                  id="reservationName"
                  class="form-control"
                  v-model="form.name" />
              </mdb-col>
              <mdb-col md="6"  sm="12">
                <label for="reservationTelephone">Numero di telefono (+39)</label>
                <input
                  type="text"
                  id="reservationTelephone"
                  class="form-control"
                  @change="form.telephone.number = form.telephone.number.replace(/\D/g,'')"
                  v-model="form.telephone.number" />
                <span class="grey-text">Mostrato ai clienti in caso di problemi in fase di prenotazione.</span>
              </mdb-col>
            </mdb-row>
            <mdb-row>
              <mdb-col>
                <hr />
                <div class="custom-control custom-checkbox custom-control-inline">
                  <input type="checkbox" class="custom-control-input"
                    id="reservation_form_ask_for_marketing_consent"
                    v-model="form.ask_for_marketing_consent"
                  >
                  <label class="custom-control-label" for="reservation_form_ask_for_marketing_consent">
                    Chiedi a chi prenota il consenso per il trattamento dei dati personali ai fini di marketing.<br />
                    <span class="grey-text">Necessario per poter utilizzare i dati del cliente per l'invio di newsletter e/o materiale promozionale. Come previsto dal GDPR, l'utente non è obbligato a fornire il consenso e può effettuare la prenotazione anche senza aver dato il permesso per marketing. Nella dashboard di EasyMenu.cloud potrete consultare e scaricare una lista di tutti gli utenti che hanno fornito il consenso, in modo tale da includerli nelle vostre liste promozionali.</span>
                  </label>
                </div>
              </mdb-col>
            </mdb-row>
          </div>
          <div class="container card card-body" v-if="currentTab == 'notifications'">
            Metodi di notifica della prenotazione. Aggiungendo più indirizzi email, verrà inviata una email ad ogni indirizzo.
            
            <div class="card mb-2" v-for="(_, i) of form.notifications" :key="'notification-method-card-'+i">
              <div class="card-header">
                <mdb-row>
                  <mdb-col md="6" sm="12">
                    Notifica {{ i+1 }}
                  </mdb-col>
                  <mdb-col md="6" sm="12" class="text-right">
                    <mdb-btn size="sm" outline="danger" @click="form.notifications.splice(i, 1)"><i class="fas fa-trash-alt"></i> Elimina notifica</mdb-btn>
                  </mdb-col>
                </mdb-row>
              </div>
              <div class="card-body">
                <mdb-row>
                  <mdb-col md="6" sm="12">
                    <label :for="'notificationType'+i" class="grey-text">Tipo notifica</label>
                    <select :id="'notificationType'+i" class="form-control" v-model="form.notifications[i].type">
                      <option value="email">Email</option>
                    </select>
                  </mdb-col>
                  <mdb-col v-if="form.notifications[i].type == 'email'" md="6" sm="12">
                    <label :for="'notificationEmail'+i" class="grey-text">Email notifica prenotazione</label>
                    <input
                      type="email"
                      :id="'notificationEmail'+i"
                      class="form-control"
                      required
                      v-model="form.notifications[i].email" />
                  </mdb-col>
                </mdb-row>
              </div>
            </div>
            <div class="text-center">
                <button type="button" class="btn btn-outline-primary"
                    @click="form.notifications.push({ type: 'email', email: '' })">
                    <i class="fas fa-plus"></i> Aggiungi notifica
                </button>
            </div>
          </div>
          <div class="container card card-body" v-if="currentTab == 'reservations'">
            <mdb-row>
              <mdb-col>
                <mdb-row>
                  <mdb-col col="12">
                    <label class="grey-text">Cosa fare se il cliente vuole prenotare per un giorno non prenotabile?</label>
                    <div class="form-check mb-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="notReservableDateBehaviour"
                        id="notReservableDateBehaviour1"
                        v-model="form.date_not_available_behaviour"
                        value="1"
                      />
                      <label class="form-check-label" for="notReservableDateBehaviour1"><b>Opzione consigliata.</b> Oltre ai pulsanti per le date prenotabili, mostra al cliente un pulsante "Prenota per un'altra data": se il cliente clicca quel pulsante, viene chiesto di contattare telefonicamente la struttura.</label>
                    </div>
                    <div class="form-check mb-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="notReservableDateBehaviour"
                        id="notReservableDateBehaviour2"
                        v-model="form.date_not_available_behaviour"
                        value="2"
                      />
                      <label class="form-check-label" for="notReservableDateBehaviour2"> Oltre ai pulsanti per le date prenotabili, mostra al cliente un pulsante "Prenota per un'altra data": se il cliente clicca quel pulsante, viene mostrato un errore "data non prenotabile".</label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="notReservableDateBehaviour"
                        id="notReservableDateBehaviour3"
                        v-model="form.date_not_available_behaviour"
                        value="3"
                      />
                      <label class="form-check-label" for="notReservableDateBehaviour3"> Vengono mostrati solo i pulsanti relativi alle date prenotabili.</label>
                    </div>
                    <hr />
                  </mdb-col>
                </mdb-row>
                <h5 class="mt-4">Regole disponibilità prenotazione</h5>
                <div v-for="(generic_rule, i) in form.generic_rules" :key="'reservation_form_create_generic_rule_create_'+i">
            
                  <div class="card mb-3">
                    <div class="card-header">
                      <mdb-row>
                        <mdb-col md="6" sm="12">
                          Regola {{ i+1 }}
                        </mdb-col>
                        <mdb-col md="6" sm="12" class="text-right">
                          <mdb-btn size="sm" outline="danger" @click="form.generic_rules.splice(i, 1)"><i class="fas fa-trash-alt"></i> Elimina regola</mdb-btn>
                        </mdb-col>
                      </mdb-row>
                    </div>
                    <div class="card-body">
                      <mdb-row>
                        <mdb-col md="6" sm="12">
                          <label class="grey-text"
                            :for="'reservationMaxNumberOfPeopleRule'+i">Limite persone per singola prenotazione</label>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text">Fino a</span>
                              </div>
                              <input :id="'reservationMaxNumberOfPeopleRule'+i" type="number" min="1" max="100" step="1" class="form-control" v-model="form.generic_rules[i].max_number_of_people">
                              <div class="input-group-append">
                                <span class="input-group-text">pax</span>
                              </div>
                            </div>
                        </mdb-col>
                        <mdb-col md="6" sm="12">
                          <label class="grey-text"
                            :for="'reservationMaxDaysAheadRule'+i">Fino a quando può prenotare il cliente?</label>
                          <select
                            class="browser-default custom-select" required
                            :id="'reservationMaxDaysAheadRule'+i"
                            v-model="form.generic_rules[i].max_days_ahead"
                          >
                            <option value="0">Solo per il giorno stesso</option>
                            <option value="1">Fino al giorno dopo</option>
                            <option
                              v-for="(_, daysAfter) in Array(30-1)"
                              :key="form.generic_rules[i]+'max_days_ahead_option_'+daysAfter"
                              :value="daysAfter+2"
                            >Fino a {{daysAfter+2}} giorni dopo</option>
                          </select>
                        </mdb-col>
                      </mdb-row>
                      <hr />
                      <div class="row">
                        <div class="col-md-6 col-sm-12">
                          <label>Giorni della settimana di attività di questa regola</label>
                          <div>
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input type="checkbox" class="custom-control-input"
                                :id="'reservation_form_create_generic_rule_create_dow_1_'+i"
                                v-model="form.generic_rules[i].days_of_the_week[1]"
                              >
                              <label class="custom-control-label" :for="'reservation_form_create_generic_rule_create_dow_1_'+i">Lun</label>
                            </div>
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input type="checkbox" class="custom-control-input"
                                :id="'reservation_form_create_generic_rule_create_dow_2_'+i"
                                v-model="form.generic_rules[i].days_of_the_week[2]">
                              <label class="custom-control-label" :for="'reservation_form_create_generic_rule_create_dow_2_'+i">Mar</label>
                            </div>
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input type="checkbox" class="custom-control-input"
                                :id="'reservation_form_create_generic_rule_create_dow_3_'+i"
                                v-model="form.generic_rules[i].days_of_the_week[3]">
                              <label class="custom-control-label" :for="'reservation_form_create_generic_rule_create_dow_3_'+i">Mer</label>
                            </div>
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input type="checkbox" class="custom-control-input"
                                :id="'reservation_form_create_generic_rule_create_dow_4_'+i"
                                v-model="form.generic_rules[i].days_of_the_week[4]" />
                              <label class="custom-control-label" :for="'reservation_form_create_generic_rule_create_dow_4_'+i">Gio</label>
                            </div>
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input type="checkbox" class="custom-control-input"
                                :id="'reservation_form_create_generic_rule_create_dow_5_'+i"
                                v-model="form.generic_rules[i].days_of_the_week[5]">
                              <label class="custom-control-label" :for="'reservation_form_create_generic_rule_create_dow_5_'+i">Ven</label>
                            </div>
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input type="checkbox" class="custom-control-input"
                                :id="'reservation_form_create_generic_rule_create_dow_6_'+i"
                                v-model="form.generic_rules[i].days_of_the_week[6]">
                              <label class="custom-control-label" :for="'reservation_form_create_generic_rule_create_dow_6_'+i">Sab</label>
                            </div>
                            <div class="custom-control custom-checkbox custom-control-inline">
                              <input type="checkbox" class="custom-control-input"
                                :id="'reservation_form_create_generic_rule_create_dow_0_'+i"
                                v-model="form.generic_rules[i].days_of_the_week[0]">
                              <label class="custom-control-label" :for="'reservation_form_create_generic_rule_create_dow_0_'+i">Dom</label>
                            </div>
                          </div>  
                        </div>
                        <div class="col-md-6 col-sm-12">
                          <label class="grey-text"
                            :for="'reservationLimitRuleToDates-'+i">Questa regola sarà valida sempre o per un periodo specifico?</label>
                          <select
                            class="browser-default custom-select" required
                            :id="'reservationLimitRuleToDates-'+i"
                            v-model="form.generic_rules[i].validity_dates"
                            @change="form.generic_rules[i].start=null; form.generic_rules[i].end=null"
                          >
                            <option value="always">La regola sarà valida sempre</option>
                            <option value="limit">Voglio impostare una data di inizio e fine</option>
                          </select>

                          <mdb-row v-if="form.generic_rules[i].validity_dates == 'limit'">
                            <mdb-col md="6" sm="12">
                              <label class="grey-text"
                                :for="'reservationLimitRuleToDates_StartingDate-'+i">Data di inizio validità</label>
                              <input
                                type="date"
                                class="browser-default custom-select" required
                                :id="'reservationLimitRuleToDates_StartingDate-'+i"
                                v-model="form.generic_rules[i].start"
                              />
                            </mdb-col>
                            <mdb-col md="6" sm="12">
                              <label class="grey-text"
                                :for="'reservationLimitRuleToDates_EndingDate-'+i">Ultima data validità</label>
                              <input
                                type="date"
                                class="browser-default custom-select" required
                                :id="'reservationLimitRuleToDates_EndingDate-'+i"
                                v-model="form.generic_rules[i].end"
                              />
                            </mdb-col>
                          </mdb-row>
                          
                        </div>
                      </div>
                      <hr />
                      <label class="grey-text">Orari prenotabili: per che orari possono prenotare i clienti?</label>
                      <div class="row">
                        <div v-for="(time, j) in form.generic_rules[i].times" :key="'reservation_form_create_generic_rule_create_'+i+'_time_create_'+j">
                          <div class="card m-1">
                            <div class="card-body">
                              <div class="row">
                                <div class="col">
                                  <div class="input-group">
                                    <select
                                      class="browser-default custom-select" required
                                      v-model="form.generic_rules[i].times[j].hour"
                                    >
                                      <option value="">HH</option>
                                      <option
                                        v-for="(_, hour) in Array(24)"
                                        :value="hour"
                                        :key="'reservation_form_create_generic_rule_create_'+i+'_time_create_'+j+'_h'+hour"
                                      >{{ ('0'+hour).slice(-2) }}</option>
                                    </select>
                                    <select class="browser-default custom-select" required
                                      v-model="form.generic_rules[i].times[j].minute"
                                    >
                                      <option value="">MM</option>
                                      <option
                                        v-for="minutes in [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50]"
                                        :key="'reservation_form_create_generic_rule_create_'+i+'_time_create_'+j+'_m'+minutes"
                                        :value="minutes"
                                      >{{ ('0'+minutes).slice(-2) }}</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col">
                                  <mdb-btn size="sm" outline="danger" @click="form.generic_rules[i].times.splice(j, 1)"><i class="fas fa-trash-alt"></i></mdb-btn>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <div class="custom-control custom-checkbox custom-control-inline">
                                    <input type="checkbox" class="custom-control-input"
                                      :id="`reservation_form_generic_rule_${i}_time_${j}_set_max_total_number_of_people`"
                                      :checked="form.generic_rules[i].times[j].max_total_number_of_people != null"
                                      @change="changeReservationRuleTimePeopleLimit($event, i, j)"
                                    >
                                    <label class="custom-control-label" :for="`reservation_form_generic_rule_${i}_time_${j}_set_max_total_number_of_people`">Limita persone totali per questo orario</label>
                                  </div>
                                  <div v-if="form.generic_rules[i].times[j].max_total_number_of_people != null" class="input-group mb-3">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Max</span>
                                    </div>
                                    <input type="number" min="0" max="1000" step="1" required class="form-control" v-model="form.generic_rules[i].times[j].max_total_number_of_people">
                                    <div class="input-group-append">
                                      <span class="input-group-text">pax</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col text-center">
                          <mdb-btn size="sm" outline="primary" @click="form.generic_rules[i].times.push({hour:'', minute:'', max_total_number_of_people: null})">
                              <i class="fas fa-plus"></i> Aggiungi orario a questa regola
                          </mdb-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <hr />
                    <button type="button" class="btn btn-outline-primary"
                        @click="form.generic_rules.push({
                          max_number_of_people: 4,
                          max_days_ahead: 0,
                          days_of_the_week: {0:false, 1:false, 2:false, 3:false, 4:false, 5:false, 6:false, 0:false},
                          times: [],
                          validity_dates: 'always',
                          start: null,
                          end: null
                        })">
                        <i class="fas fa-plus"></i> Aggiungi regola
                    </button>
                </div>
              </mdb-col>
            </mdb-row>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mdbRow, mdbCol, mdbBtn } from 'mdbvue';
export default {
    name: "ReservationFormButton",
    components: {
        mdbRow, mdbCol,
        mdbBtn
    },
    data() {
        return {
            loading: false,
            currentTab: 'general',
            form: null,

            modalTitle: '',
            submitButtonText: '',
        }
    },
    async created() {
      await this.updateView();
    },
    watch: {
      '$route' () {
        this.updateView();
      }
    },
    computed: mapGetters(['getReservationFormByUrl']),
    methods: {
        ...mapActions(['fetchReservationForms', 'createReservationForm', 'updateReservationForm']),
        updateView: function() {
          this.form = {
            name: '',
            telephone: {number: null},
            ask_for_marketing_consent: false,
            notifications: [],
            date_not_available_behaviour: 1,
            generic_rules: []
          };
          this.fetchReservationForms();
          this.reservationForm = this.getReservationFormByUrl(this.$route.params.reservation_form_url)
          
          if (this.reservationForm) {
            // If update mode

            this.uploadDataToForm();
            this.modalTitle = `Impostazioni modulo di prenotazione "${this.form.name}"`
            this.submitButtonText = 'Salva'
          } else {
            // If create mode

            this.modalTitle = 'Nuovo modulo di prenotazione'
            this.submitButtonText = 'Crea modulo'
          }
        },
        uploadDataToForm: function() {
          if (this.reservationForm) {
            // If update mode

            // Store array of days
            let genericRules = this.reservationForm.generic_rules;

            // Deep copy current reservationForm to avoid editing original object
            this.form = {...this.form, ...JSON.parse(JSON.stringify(this.reservationForm))};

            // For each rule
            for (const [i, genericRule] of genericRules.entries()) {

              // Convert array of days [1, 2] to object of days {0: false, 1: true, 2: true, 3: false....}
              this.form.generic_rules[i].days_of_the_week = {0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false}
              for (let activeDayOfWeek of genericRule.days_of_the_week) {
                this.form.generic_rules[i].days_of_the_week[activeDayOfWeek] = true;
              }

              // Show start/end date inputs by setting validity_dates to 'limit'
              if (this.form.generic_rules[i].start || this.form.generic_rules[i].end) {
                this.form.generic_rules[i].validity_dates = 'limit';
              } else {
                this.form.generic_rules[i].validity_dates = 'always';
              }
            }
          } else {
            // If create mode

            this.form = {
                name: '',
                telephone: {number: null},
                ask_for_marketing_consent: false,
                notifications: [],
                date_not_available_behaviour: 1,
                generic_rules: [ ]
            }
          }

        },
        submitForm: function(e) {
            e.preventDefault();
            this.loading = true;

            if (this.reservationForm) {
              // If update mode

              this.updateReservationForm(this.form).then(res => {
                  this.loading = false;
                  if (res.status == 200) {
                    this.$router.push('/reservation/forms')
                  }
              }).catch(err => {
                  this.loading = false;
                  alert(err);
              })

          } else {
            // If create mode

            this.createReservationForm(this.form).then(res => {
                this.loading = false;
                if (res.status == 200) {
                    this.$router.push('/reservation/forms')
                }
            }).catch(err => {
                this.loading = false;
                alert(err);
            })
          }
        },
        changeReservationRuleTimePeopleLimit(e, i, j) {
          if (e.target.checked) {
            this.form.generic_rules[i].times[j].max_total_number_of_people = "";
          } else {
            this.form.generic_rules[i].times[j].max_total_number_of_people = null;
          }
        }
    }
};
</script>