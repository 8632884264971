<template>
	<mdb-row class="justify-content-center">
		<mdb-col style="max-width:800px">
      <mdb-card>
        <mdb-card-body>
            <p class="h4 text-center">Verifica email</p>

            <div class="text-center">
              <div class="spinner-border" role="status" v-if="loading">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <div class="alert alert-success" v-if="alerts.email_activated">
              L'email è stata attivata correttamente. Cliccare il pulsante sotto per accedere al pannello di amministrazione.
              <p class="text-center">
                <router-link to="/auth" class="btn btn-primary">
                    Accedi
                </router-link>
              </p>
            </div>

            <div class="alert alert-warning" v-if="alerts.activation_error">
              Spiacenti, il link non è valido.<br />
              Vi preghiamo di contattarci via email a <a href="mailto:info@easymenu.cloud">info@easymenu.cloud</a>.
            </div>
        </mdb-card-body>
      </mdb-card>
		</mdb-col>
	</mdb-row>
</template>


<script>
import { mdbRow, mdbCol, mdbCard, mdbCardBody } from "mdbvue";
import axios from 'axios';

export default {
	name: "EmailActivation",
	components: {
		mdbRow,
		mdbCol,
		mdbCard,
		mdbCardBody,
	},
	data() {
		return {
			loading: true,
      alerts: {
        email_activated: false,
        activation_error: false,
      }
		};
	},
  created() {
    const activationToken = this.$route.params.token;

    axios
        .post(`${this.$api_hostname}/api/v1/auth/activate_email`, {
            'token': activationToken
        })
        .then(response => {
            this.loading = false;
            if (response.status == 200) {
                if(response.data.email_activated) {
                  this.alerts.email_activated = true;
                } else {
                  this.alerts.activation_error = true;
                }
            } else {
              this.alerts.activation_error = true;
            }
        }).catch(() => {
            this.loading = false;
            this.alerts.activation_error = true;
        });
  },
};
</script>