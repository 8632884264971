<template>
  <div class="flexible-content" style="background-color:#ededee">
    <!--Navbar-->
    <mdb-navbar class="flexible-navbar white" light style="width:100%;max-width:1500px;margin:auto">
      <mdb-navbar-brand>
        <router-link to="/">
          <img src="@/assets/images/EasyMenu.cloud-header-blue.svg" height="25" style="max-width:90%" />
        </router-link>
      </mdb-navbar-brand>
      <mdb-navbar-toggler>
        <mdb-navbar-nav left v-if="getUserToken">
          <router-link to="/menu" class="btn p-0" :class="(route_name.startsWith('Menu')) ? 'btn-outline-primary' : 'btn-outline-white'"><mdb-nav-item waves-fixed>Menu</mdb-nav-item
          ></router-link>
          <router-link to="/reservation" class="btn p-0" :class="(route_name.startsWith('Reservation')) ? 'btn-outline-primary' : 'btn-outline-white'"><mdb-nav-item waves-fixed>Prenotazioni</mdb-nav-item
          ></router-link>
        </mdb-navbar-nav>
        <mdb-navbar-nav right v-if="getUserToken">
          <mdb-dropdown tag="li" class="nav-item btn p-0">
            <mdb-dropdown-toggle tag="a" navLink slot="toggle" waves-fixed>
          <img src="@/assets/images/user_icon.svg" class="rounded-circle z-depth-0"
            alt="avatar image" height="20"> Account</mdb-dropdown-toggle>
            <mdb-dropdown-menu>
              <mdb-dropdown-item @click="logout()">Logout</mdb-dropdown-item>
            </mdb-dropdown-menu>
          </mdb-dropdown>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>
    <!--/.Navbar-->
    <main>
      <div class="p-3">
        <router-view></router-view>
      </div>
      <ftr color="primary-color-dark" class="text-center font-small darken-2">
        <div v-if="getUserToken" class="p-4">
          <mdb-btn
            outline="white"
            tag="a"
            href="https://www.youtube.com/watch?v=F7yj_U0cFE8"
            target="_blank"
            ><mdb-icon icon="play" class="mr-2" /> Visualizza video guida per la creazione del menu digitale</mdb-btn>
        </div>
        <div v-else class="p-4">
          <mdb-btn
            outline="white"
            tag="a"
            href="https://easymenu.cloud/"
            ><mdb-icon icon="home" class="mr-2" /> Torna al sito</mdb-btn>
        </div>
        <p class="footer-copyright mb-0 py-3 text-center">
          &copy; {{ new Date().getFullYear() }} - <a href="https://easymenu.cloud/"><img src="@/assets/images/EasyMenu.cloud-header-white.svg" height="15" style="max-width:90%" /></a>
        </p>
      </ftr>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavItem,
  mdbNavbarNav,
  mdbNavbarToggler,
  mdbBtn,
  mdbIcon,
  mdbFooter,
  waves
} from "mdbvue";
import { mdbDropdown, mdbDropdownMenu, mdbDropdownToggle, mdbDropdownItem } from 'mdbvue';
  
export default {
  name: "Dashboard",
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavItem,
    mdbNavbarNav,
    mdbNavbarToggler,
    mdbBtn,
    // mdbListGroup,
    // mdbListGroupItem,
    mdbIcon,
    ftr: mdbFooter,
    mdbDropdown, mdbDropdownMenu, mdbDropdownToggle, mdbDropdownItem
  },
  computed: {
    ...mapGetters(['getUserToken']),
    route_name: function() { return this.$route.name }
  },
  watch: {
    'getUserToken': function(newToken) {
      if (newToken === null) {
        this.logout();
      }
    }
  },
  created () {
    this.fetchUserToken();
  },
  methods: {
    ...mapActions(['deleteUserToken', 'fetchUserToken']),
    logout: function() {
      this.deleteUserToken();
      this.$router.push('/auth');
    }
  },
  mixins: [waves]
};
</script>

<style scoped>
main {
  background-color: #ededee;
}

.footer-copyright img {
  opacity: 0.7;
  transition: 0.3s;
}
.footer-copyright img:hover {
  opacity: 1;
  transition: 0.3s;
}

/* .flexible-content {
  transition: padding-left 0.3s;
  padding-left: 270px;
}

.flexible-navbar {
  transition: padding-left 0.5s;
  padding-left: 270px;
}

.sidebar-fixed {
  left: 0;
  top: 0;
  height: 100vh;
  width: 270px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1050;
  background-color: #fff;
  padding: 1.5rem;
  padding-top: 0;
}

.sidebar-fixed .logo-wrapper img {
  width: 100%;
  padding: 2.5rem;
}

.sidebar-fixed .list-group-item {
  display: block !important;
  transition: background-color 0.3s;
}

.sidebar-fixed .list-group .active {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

@media (max-width: 1199.98px) {
  .sidebar-fixed {
    display: none;
  }
  .flexible-content {
    padding-left: 0;
  }
  .flexible-navbar {
    padding-left: 10px;
  }
} */
</style>
