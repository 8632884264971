const state = {
};

const getters = {
  getStringFromMultilangObject: () => (obj) => {
    if (obj) {
      if ('it_it' in obj) {
        return obj['it_it']
      }
    }
    return "";
  }
};

const actions = {
};

const mutations = {
};

export default {
    state,
    getters,
    actions,
    mutations
}