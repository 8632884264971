export const LOCALHOST = false

export const API_HOSTNAME = (LOCALHOST) ? 'http://localhost:3000' : 'https://easymenu-cloud-api.herokuapp.com'
export const STATIC_HOSTNAME = 'https://static.easymenu.cloud'

export const getSortingPosition = function (initialArray, oldIndex, newIndex, getId) {
  let arr = [...initialArray];
  if (newIndex >= arr.length) {
      var k = newIndex - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

  // Obtain a dict {'price_id': sorting_position}
  let sortingPosition = {};
  for (let i=0; i<arr.length; ++i) {
      sortingPosition[getId(arr[i])] = i;
  }

  return {
    'finalArray': arr,
    'sortingPositions': sortingPosition
  }
}