<template>
	<mdb-row class="justify-content-center m-auto">
		<mdb-col style="max-width:400px">
			<mdb-card>
				<mdb-card-body>
					<p class="h4 text-center">Recupero password</p>

					<div class="alert alert-danger" v-if="emailNotFound">
						L'email inserita non risulta registrata a EasyMenu.cloud. <a href="/auth">Clicca qui per registrarti</a>.
					</div>

					<div class="alert alert-success" v-if="success">
						Ti abbiamo inviato una email contenente le istruzioni per resettare la password.
					</div>

					<form v-else @submit="doForgotPassword">
						<vue-recaptcha
							ref="recaptcha"
							sitekey="6LejVAgdAAAAAMRKFdzDZIB2JiwF6e0EssOp9qQX"
							size="invisible"
							:loadRecaptchaScript="true"
							@verify="onCaptchaVerified"
							@expired="resetCaptcha"
						></vue-recaptcha>
						<div class="grey-text">
							<mdb-input
								label="Email aziendale"
								icon="envelope"
								group
								type="email"
								validate
								error="wrong"
								success="right"
								v-model="form.email"
								@change="emailNotFound = false"
							/>
						</div>
						<div class="text-center py-4 mt-3">
							<mdb-btn color="primary" type="submit" :disabled="loading">
								<span
									class="spinner-border spinner-border-sm mr-4"
									role="status"
									aria-hidden="true"
									v-if="loading"
								></span>
								Recupera password
							</mdb-btn>
						</div>
						<div class="text-center py-4 mt-3">
							<a href="/auth" class="btn">
								Torna al login
							</a>
						</div>
					</form>
				</mdb-card-body>
			</mdb-card>
		</mdb-col>
	</mdb-row>
</template>


<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { mdbRow, mdbCol, mdbCard, mdbCardBody, mdbInput, mdbBtn } from "mdbvue";
import VueRecaptcha from 'vue-recaptcha'

function validateEmail(email) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export default {
	name: "AuthenticationForgotPassword",
  props: ['email'],
	components: {
		VueRecaptcha,
		mdbRow,
		mdbCol,
		mdbCard,
		mdbCardBody,
		mdbInput,
		mdbBtn,
	},
	data() {
		return {
			loading: false,
			success: false,
			form: {
				email: '',
			},
			emailNotFound: false,
		};
	},
  computed: mapGetters(['getAuthEmail']),
  created() {
    this.form.email = this.getAuthEmail;
  },
	methods: {
		doForgotPassword: function (event) {
			event.preventDefault();

			if (validateEmail(this.form.email)) {
				this.$refs.recaptcha.execute()
			} else {
				alert("Please insert a valid email address");
			}
		},
		onCaptchaVerified: function (token) {
			this.loading = true;
			this.resetCaptcha()

			axios.post(`${this.$api_hostname}/api/v1/auth/password/request_reset`, {
				email: this.form.email,
				captcha_token: token
			})
			.then((response) => {
				this.loading = false;
				if (response.status == 200) {
					this.success = true;
				} else {
					alert("Error");
				}
			})
			.catch((err) => {
				this.loading = false;
				if (err.response.status === 404) {
					this.emailNotFound = true;
				} else {
					alert(
						"Errore. Si prega di controllare la connessione ad Internet e di provare ad aggiornare la pagina. Errore: " +
							err
					);
				}
			});
	
		},
		resetCaptcha() {
				this.$refs.recaptcha.reset()
		},
	},
};
</script>

<style scoped>
.profile-card-footer {
	background-color: #f7f7f7 !important;
	padding: 1.25rem;
}
.card.card-cascade .view {
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15), 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}
</style>
