<template>
  <mdb-modal @close="setShowModalMenuUpdate(false)" removeBackdrop>
    <form @submit="submitForm">
      <mdb-modal-header>
        <mdb-modal-title>Modifica menu</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <!-- <mdb-row>
          <mdb-col col="8">
            <label for="model_add_menu_input_image" class="grey-text">Immagine</label>
            <input
              type="file"
              accept="image/*"
              @change="$parent.modal_menu_data.menu_image = $event.target.files[0]"
              id="model_add_menu_input_image"
              class="form-control"
            />
          </mdb-col>
          <mdb-col col="4" class="text-center">
            <img v-if="$parent.modal_menu_data.menu_image_id" :src="$static_hostname + '/images/' + $parent.modal_menu_data.menu_image_id" style="max-width:50%;max-height:100px" /><br />
            <mdb-btn v-if="$parent.modal_menu_data.menu_image_id" color="none" @click.native="$parent.deleteMenuImage($event, $parent.modal_menu_data.menu_id)">Elimina immagine</mdb-btn>
          </mdb-col>
        </mdb-row>
        <br /> -->
        <label for="model_add_menu_input_name" class="grey-text">Nome pubblico</label>
        <input
          type="text"
          id="model_add_menu_input_name"
          class="form-control"
          v-model="form.name" />
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="none" @click.native="setShowModalMenuUpdate(false)">Chiudi</mdb-btn>
        <mdb-btn type="submit" color="primary" :disabled="loading">
            <span class="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true" v-if="loading"></span>
            Modifica
        </mdb-btn>
      </mdb-modal-footer>
    </form>
  </mdb-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { //mdbRow, mdbCol,
mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn } from 'mdbvue';
export default {
    name: "ModalMenuEdit",
    components: {
        // mdbRow, mdbCol,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        mdbBtn
    },
    data() {
        return {
            loading: false,
            form: {
                name: ''
            }
        }
    },
    created() {
        this.form.name = this.getMenu(this.getModalMenuUpdateId).menu_name
    },
    computed: mapGetters(['getMenu', 'getModalMenuUpdateId']),
    methods: {
        ...mapActions(['setShowModalMenuUpdate', 'updateMenu']),
        submitForm: function(e) {
            e.preventDefault();
            this.loading = true;
            this.updateMenu({
              _id: this.getModalMenuUpdateId,
              short_url: this.getMenu(this.getModalMenuUpdateId).menu_short_url,
              name: this.form.name
            }).then(res => {
                this.loading = false;
                if (res.status == 200) {
                    this.setShowModalMenuUpdate(false);
                }
            }).catch(err => {
                this.loading = false;
                alert(err);
            })
        }
    }
};
</script>