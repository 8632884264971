<template>
  <div>
    <NextReservations v-if="Object.keys(getReservationForms).length > 0" />
    <div v-else class="card card-body">
      <div class="alert alert-info">
        Per iniziare a ricevere le prenotazioni, occorre creare un modulo di prenotazione.<br />
        Una volta creato il modulo, i tuoi clienti potranno prenotare utilizzando il link (o il codice QR) che ti viene assegnato.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NextReservations from '../components/NextReservations'

export default {
  name: "Reservations",
  components: {
    NextReservations
  },
  data() {
    return {
      deferredPrompt: null,
      ajax_page_error: false,
      ajax_page_loading: false,
      modalMenuQrCodeMenuId: null,
    };
  },
  created() {
    this.fetchUserToken();
    if (Object.keys(this.getReservationForms).length == 0) {
      this.ajax_page_loading = true;
    }
    this.fetchReservationForms().then(() => {
      this.ajax_page_loading = false;
    }).catch((err) => {
      console.log(err);
      this.ajax_page_loading = false;
      this.ajax_page_error = true;
    });
  },
  computed: mapGetters(['getReservationForms']),
  methods: mapActions(['fetchUserToken', 'fetchReservationForms']),
};
</script>
