import Menu from '@/modules/menu/views/index'
import MenuDashboard from '@/modules/menu/views/Dashboard'
import MenuMenu from '@/modules/menu/views/Menu'

export default {
  path: 'menu',
  name: 'Menus',
  component: Menu,
  children: [
    {
      path: '',
      name: 'MenuDashboard',
      component: MenuDashboard
    },
    {
      path: ':short_url',
      name: 'MenuMenu',
      component: MenuMenu
    }
  ]
}