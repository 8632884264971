<template>
    <mdb-modal @close="setShowModalCategoryAdd(false)">
    <mdb-modal-header>
        <mdb-modal-title>Nuova categoria</mdb-modal-title>
    </mdb-modal-header>
    <form @submit="submitForm">
        <mdb-modal-body>
            <label for="model_add_food_category_input_name" class="grey-text">Nome</label>
            <input type="text" id="model_add_food_category_input_name" class="form-control" v-model="form.name" required />
            <br/>
            <label for="model_add_food_category_input_description" class="grey-text">Descrizione</label>
            <textarea id="model_add_food_category_input_description" class="form-control" v-model="form.description"></textarea>
            <br/>
            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="model_add_food_category_input_show"
                v-model="form.show">
                <label class="custom-control-label" for="model_add_food_category_input_show">Mostra questa categoria nel menu pubblico</label>
            </div>
        </mdb-modal-body>
        <mdb-modal-footer>
            <mdb-btn color="none" @click.native="setShowModalCategoryAdd(false)">Annulla</mdb-btn>
            <mdb-btn color="primary" type="submit" :disabled="loading">
                <span class="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true" v-if="loading"></span>
                Aggiungi
            </mdb-btn>
        </mdb-modal-footer>
    </form>
    </mdb-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn } from 'mdbvue';
export default {
  props: ['menu_id'],
  name: "ModalCategoryEdit",
  components: {
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn
  },
  data() {
      return {
          loading: false,
          form: {
              name: '',
              description: '',
              show: true,
          }
      }
  },
  computed: mapGetters(['getShowModalCategoryAdd', 'getCategory']),
  methods: {
      ...mapActions(['setShowModalCategoryAdd', 'addCategory']),
      submitForm: function(e) {
          e.preventDefault();
          this.loading = true;
          this.addCategory({
              menu_id: this.menu_id,
              category: {
                  name: this.form.name,
                  description: this.form.description,
                  show: this.form.show,
              }
          }).then(res => {
              this.loading = false;
              if (res.status == 200) {
                  this.setShowModalCategoryAdd(false);
              }
          }).catch(err => {
              this.loading = false;
              alert(err);
          })
      }
  }
};
</script>