<template>
  <div>
    <mdb-card class="h-100">
      <mdb-card-header>
          <h5 class="text-truncate">{{ reservationForm.name }}</h5>
        </mdb-card-header>
      <mdb-card-body>
        <mdb-row>
          <mdb-col>
            <!-- <ReservationFormButton :reservationForm="reservationForm" /> -->
            <router-link class="btn btn-primary w-100"
              :to="`/reservation/form/${reservationForm.short_url}`"
            >
              <i class="fas mr-2 fa-cog"></i>
              Impostazioni
            </router-link>

            <ReservationFormQRCodeButton :reservationForm="reservationForm" />
            <a :href="$public_reservation_hostname + '/' + reservationForm.short_url" target="_blank" class="btn w-100">
                <i class="fas fa-external-link-alt mr-2"></i> Visualizza modulo
            </a>
          </mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <ReservationFormDeleteButton :reservationForm="reservationForm" />
          </mdb-col>
        </mdb-row>
      </mdb-card-body>
    </mdb-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardHeader } from 'mdbvue';
import ReservationFormDeleteButton from '../components/ReservationFormDelete'
import ReservationFormQRCodeButton from '../components/ReservationFormQRCodeButton'

export default {
    name: "ReservationFormCard",
    props: ['reservationForm'],
    components: {
      ReservationFormDeleteButton,
      ReservationFormQRCodeButton,

      mdbRow, mdbCol,
      mdbCard,
      mdbCardBody,
      mdbCardHeader,
    },
    data() {
        return {
            loading: false,
            show: false,
            form: {
                name: '',
                generic_rules: [ ]
            }
        }
    },
    methods: {
        ...mapActions(['createReservationForm']),
        submitForm: function(e) {
            e.preventDefault();
            this.loading = true;
            this.createReservationForm(this.form).then(res => {
                this.loading = false;
                if (res.status == 200) {
                    this.show = false;
                }
            }).catch(err => {
                this.loading = false;
                alert(err);
            })
        }
    }
};
</script>