<template>
	<mdb-row class="justify-content-center m-auto">
		<mdb-col style="max-width:400px">
			<mdb-card>
				<mdb-card-body>
					<div class="alert alert-success" v-if="success">
						Ci siamo quasi! L'ultima cosa da fare è verificare l'indirizzo email
						inserito. Abbiamo inviato una email all'indirizzo {{ email }}:
						all'interno sono presenti le istruzioni per attivare l'account.
					</div>
					<form @submit="doRegistration" v-if="!success">
					<vue-recaptcha
						ref="recaptcha"
						sitekey="6LejVAgdAAAAAMRKFdzDZIB2JiwF6e0EssOp9qQX"
						size="invisible"
						:loadRecaptchaScript="true"
						@verify="onCaptchaVerified"
						@expired="resetCaptcha"
					></vue-recaptcha>
						<p class="h4 text-center">Registrazione nuova attività</p>
						<div class="grey-text">
							<mdb-input
								label="Email aziendale"
								icon="envelope"
								group
								type="email"
								validate
								error="wrong"
								success="right"
								v-model="form.email"
								@change="changeEmail()"
							/>
							<mdb-input
								label="Password"
								icon="lock"
								group
								type="password"
								minlength=6
								maxlength=50
								validate
								ref="password"
								v-model="form.password"
							/>
							<mdb-input
								label="Conferma password"
								icon="lock"
								group
								type="password"
								validate
								v-model="form.password_check"
							/>
						</div>
						<div class="text-center py-4 mt-3">
							<mdb-btn color="primary" type="submit" :disabled="loading">
								<span
									class="spinner-border spinner-border-sm mr-4"
									role="status"
									aria-hidden="true"
									v-if="loading"
								></span>
								Registrazione
							</mdb-btn>
						</div>
					</form>
				</mdb-card-body>
			</mdb-card>
		</mdb-col>
	</mdb-row>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { mdbRow, mdbCol, mdbCard, mdbCardBody, mdbInput, mdbBtn } from "mdbvue";
import VueRecaptcha from 'vue-recaptcha'

function validateEmail(email) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export default {
	name: "AuthenticationRegistration",
	components: {
		VueRecaptcha,
		mdbRow,
		mdbCol,
		mdbCard,
		mdbCardBody,
		mdbInput,
		mdbBtn,
	},
	data() {
		return {
			loading: false,
			success: false,
			form: {
				email: "",
				password: "",
				password_check: "",
			},
		};
	},
  computed: mapGetters(['getAuthEmail']),
  created() {
    this.form.email = this.getAuthEmail;
		this.$nextTick(() => {
			this.$refs.password.focus()
		})
  },
	methods: {
		...mapActions(['setAuthEmail']),
		changeEmail: function () {
			this.setAuthEmail(this.form.email);
		},
		doRegistration: function (event) {
			event.preventDefault();

			if (!validateEmail(this.form.email)) {
				alert("Please insert a valid email address");
			} else if (this.form.password !== this.form.password_check) {
				alert("Attenzione, le password non coincidono!");
			} else {
				this.$refs.recaptcha.execute()
			}
		},
		onCaptchaVerified: function (token) {
			this.loading = true;
			this.resetCaptcha()
			axios.post(`${this.$api_hostname}/api/v1/register`, {
				email: this.form.email,
				password: this.form.password,
				captcha_token: token
			})
			.then((response) => {
				this.loading = false;
				if (response.status == 200) {
					this.success = true;
				} else {
					alert("Error");
				}
			})
			.catch((err) => {
				this.loading = false;
				alert(
					"Errore. Si prega di controllare la connessione ad Internet e di provare ad aggiornare la pagina. Errore: " +
						err
				);
			});
		},
		resetCaptcha() {
				this.$refs.recaptcha.reset()
		},
	},
};
</script>

<style scoped>
.profile-card-footer {
	background-color: #f7f7f7 !important;
	padding: 1.25rem;
}
.card.card-cascade .view {
	box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15), 0 3px 12px 0 rgba(0, 0, 0, 0.15);
}
</style>
