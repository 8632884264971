<template>
    <tbody>
        <tr v-bind:key="'table-row-category-header-' + category_id" class="bg-dark text-white">
            <td>
                <mdb-btn-group size="sm" vertical>
                    <mdb-btn color="white" class="mb-0" v-if="getCurrentMenuMenu.sub_categories.length > 1"
                        :class="{ 'disabled' : category_sorting_position == 0}"
                        @click="moveCategory($event, category_sorting_position, category_sorting_position - 1)">
                        <i class="fas" :class="(category_sorting_position == 0) ? 'icon-sign-blank' : 'fa-arrow-up'"></i>
                    </mdb-btn>
                    <mdb-btn color="white" class="mb-0" v-if="getCurrentMenuMenu.sub_categories.length > 1"
                        :class="{ 'disabled' : category_sorting_position == getCurrentMenuMenu.sub_categories.length - 1}"
                        @click="moveCategory($event, category_sorting_position, category_sorting_position + 1)">
                        <i class="fas" :class="(category_sorting_position == getCurrentMenuMenu.sub_categories.length - 1) ? 'icon-sign-blank' : 'fa-arrow-down'"></i>
                    </mdb-btn>
                </mdb-btn-group>
            </td>
            <td colspan="5">
                <div class="float-left">
                    <span v-if="getCategory({menu_id, category_id}).show == false"><span class="badge badge-pill badge-light"><i class="fas fa-eye-slash"></i> Questa categoria è nascosta</span><br /></span>
                    <h3>{{ getStringFromMultilangObject(getCategory({menu_id, category_id}).name) }}</h3>
                    {{ getStringFromMultilangObject(getCategory({menu_id, category_id}).description) }}
                </div>
                <div class="float-right">
                    <mdb-btn-group vertical>
                        <mdb-btn-group>
                            <mdb-btn color="white" @click="setShowModalItemCreate({show: true, category_id})">
                                <i class="fas fa-plus"></i>
                            </mdb-btn>
                            <mdb-btn color="white" @click="openModalCategoryEdit(category_id)">
                                <i class="fas fa-pencil-alt"></i>
                            </mdb-btn>
                            <mdb-btn color="danger" @click="openModalCategoryDelete(category_id)">
                                <i class="fas fa-trash-alt"></i>
                            </mdb-btn>
                        </mdb-btn-group>
                    </mdb-btn-group>
                </div>
            </td>
        </tr>
        <template
            v-for="(item_id, food_sort_position) in getCategory({menu_id, category_id}).items">
            <MenuItem
                :key="'tr-item-'+item_id"

                :menu_id="menu_id"
                :category_id="category_id"
                :item_id="item_id"

                :sorting_position="food_sort_position" />
            <MenuPrice
                v-for="(price, price_sort_position) in getItem({menu_id, item_id}).prices"
                :key="price.price_id"

                :menu_id="menu_id"
                :category_id="category_id"
                :item_id="item_id"
                :price_id="price._id"
                :price="price"

                :sorting_position="price_sort_position" />
        </template>
    </tbody>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getSortingPosition } from "../../../constants";
import { mdbBtn, mdbBtnGroup } from 'mdbvue';
import MenuItem from './MenuItem.vue'
import MenuPrice from './MenuPrice.vue'

export default {
  props: ['menu_id', 'category_id', 'category_sorting_position'],
  name: "MenuCategory",
    components: {
      mdbBtn,
      mdbBtnGroup,
      MenuItem,
      MenuPrice
    },
  filters: {
    toPrice (value) {
      if (value == null || isNaN(value)) {
        return `N/D`;
      } else {
        value = parseFloat(value);
        return value.toFixed(2).replace('.', ',')
      }
    }
  },
  computed: mapGetters(['getStringFromMultilangObject', 'getCurrentMenuMenu', 'getCategory', 'getCategories', 'getItem']),
  methods: {
    ...mapActions([
        'setShowModalCategoryEdit', 'setModalCategoryEditId', 'setShowModalCategoryDelete', 'setModalCategoryDeleteId', 'setShowModalItemCreate', 'updateCategoriesSortingPosition']),
    openModalCategoryEdit(id) {
        this.setModalCategoryEditId(id);
        this.setShowModalCategoryEdit(true);
    },
    openModalCategoryDelete(id) {
        this.setModalCategoryDeleteId(id);
        this.setShowModalCategoryDelete(true);
    },
    moveCategory: function (event, old_index, new_index) {
        event.target.disabled = true;

        // TODO: add methods for children categories
        let { finalArray, sortingPositions } = getSortingPosition(
            this.getCurrentMenuMenu.sub_categories,
            old_index,
            new_index,
            (x => x)
        );

        this.updateCategoriesSortingPosition({
            menu_id: this.menu_id,
            parent_category_id: null,
            item_id: this.item_id,
            sortingPositions,
            finalArray,
        })
            .then((res) => {
                event.target.disabled = false;
                if (res.status != 200) {
                    alert("Error");
                }
            })
            .catch((err) => {
                event.target.disabled = false;
                alert(err);
            });
    },
  }
};
</script>