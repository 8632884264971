<template>
  <mdb-card>
    <mdb-card-body>
      
      <mdb-row v-show="ajax_page_loading">
        <mdb-col md="12" class="text-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </mdb-col>
      </mdb-row>
      <mdb-alert color="danger" v-show="ajax_page_error">
        Impossibile caricare la pagina richiesta. Si prega di verificare che la connessione a Internet funzioni correttamente e riaggiornare la pagina. Se il problema persiste si prega di contattarci.
      </mdb-alert>
      <mdb-row>
        <mdb-col xl="4" lg="6" md="12" sm="12" class="mb-4" v-for="reservationForm in getReservationForms" v-bind:key="'overviewReservationForm-' + reservationForm._id">
          <ReservationFormCard :reservationForm="reservationForm" />
        </mdb-col>
      </mdb-row>
      <router-link to="/reservation/form" class="btn btn-primary w-100">Crea nuovo modulo di prenotazione</router-link>
    </mdb-card-body>
  </mdb-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mdbRow, mdbCol, mdbCard, mdbCardBody, mdbAlert } from "mdbvue";
import ReservationFormCard from '../components/ReservationFormCard.vue'

export default {
  name: "ReservationForms",
  components: {
    ReservationFormCard,

    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbAlert,
  },
  data() {
    return {
      deferredPrompt: null,
      ajax_page_error: false,
      ajax_page_loading: false,
      modalMenuQrCodeMenuId: null,
    };
  },
  created() {
    this.fetchUserToken();
    if (Object.keys(this.getReservationForms).length == 0) {
      this.ajax_page_loading = true;
    }
    this.fetchReservationForms().then(() => {
      this.ajax_page_loading = false;
    }).catch((err) => {
      console.log(err);
      this.ajax_page_loading = false;
      this.ajax_page_error = true;
    });
  },
  computed: mapGetters(['getReservationForms']),
  methods: mapActions(['fetchUserToken', 'fetchReservationForms']),
};
</script>
