<template>
	<tr v-if="sorting_position !== undefined">
		<th scope="row"></th>
		<td>
			<mdb-btn-group
				size="sm"
				v-if="getItem({ menu_id: menu_id, item_id: item_id }).prices.length > 1"
			>
				<mdb-btn
					color="white"
					class="m-0"
					:class="{ disabled: sorting_position == 0 }"
					@click="movePrice($event, sorting_position, sorting_position - 1)"
				>
					<i class="fas fa-arrow-up"></i>
				</mdb-btn>
				<mdb-btn
					color="white"
					class="m-0"
					:class="{
						disabled:
							sorting_position ==
							getItem({ menu_id: menu_id, item_id: item_id }).prices.length - 1,
					}"
					@click="movePrice($event, sorting_position, sorting_position + 1)"
				>
					<i class="fas fa-arrow-down"></i>
				</mdb-btn>
			</mdb-btn-group>
		</td>
		<td>{{ (price.name) ? price.name["it_it"] : '' }}</td>
		<td>{{ price.price | toPrice }}</td>
		<td class="text-center">
			<div class="custom-control custom-switch">
				<input
					type="checkbox"
					class="custom-control-input"
					:id="'checkbox_availability_price-' + price_id"
					@change="togglePriceAvailability($event, price_id)"
					:checked="price.available"
				/>
				<label
					class="custom-control-label"
					:for="'checkbox_availability_price-' + price_id"
				></label>
			</div>
		</td>
		<td></td>
	</tr>
	<div v-else>
		<div class="row">
			<div class="col-lg-10 col-md-8 col-sm-8 col-5 text-right">
				{{ (price.name) ? price.name["it_it"] : '' }}
			</div>
			<div class="col-lg-1 col-md-2 col-sm-2 col-4">
				{{ price.price | toPrice }}
			</div>
			<div class="col-lg-1 col-md-2 col-sm-2 col-3">
				<div class="custom-control custom-switch">
					<input
						type="checkbox"
						class="custom-control-input"
						@change="togglePriceAvailability($event, price_id)"
						:checked="price.available"
					/>
					<label
						class="custom-control-label"
						:for="'checkbox_availability_price-' + price_id"
					></label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getSortingPosition } from "../../../constants";
import { mdbBtn, mdbBtnGroup } from "mdbvue";

export default {
	props: [
		"menu_id",
		"category_id",
		"item_id",
		"price_id",
		"price",
		"sorting_position",
	],
	name: "MenuPrice",
	components: {
		mdbBtn,
		mdbBtnGroup,
	},
	filters: {
		toPrice(value) {
			if (value == null || isNaN(value)) {
				return `N/D`;
			} else {
				value = parseFloat(value);
				return value.toFixed(2).replace(".", ",");
			}
		},
	},
	computed: mapGetters(["getItem"]),
	methods: {
		...mapActions(["updatePriceAvailability", "updatePricesSortingPosition"]),
		togglePriceAvailability(e, price_id) {
			const available = e.target.checked;
			e.target.disabled = true;
			this.updatePriceAvailability({
				menu_id: this.menu_id,
				item_id: this.item_id,
				price_id,
				available,
			})
				.then((res) => {
					e.target.disabled = false;
					if (res.status != 200) {
						e.target.checked = !available;
					}
				})
				.catch((err) => {
					e.target.disabled = false;
					e.target.checked = !available;
					alert(err);
				});
		},
		movePrice: function (event, old_index, new_index) {
			event.target.disabled = true;

			let { finalArray, sortingPositions } = getSortingPosition(
				this.getItem({ menu_id: this.menu_id, item_id: this.item_id }).prices,
				old_index,
				new_index,
				(x => x._id)
			);

			this.updatePricesSortingPosition({
				menu_id: this.menu_id,
				item_id: this.item_id,
				sortingPositions,
				finalArray,
			})
				.then((res) => {
					event.target.disabled = false;
					if (res.status != 200) {
						alert("Error");
					}
				})
				.catch((err) => {
					event.target.disabled = false;
					alert(err);
				});
		},
	},
};
</script>