<template>
  <div>
    <mdb-row>
      <mdb-col>
        <input
          type="text"
          class="form-control mb-4"
          v-model="filter.search"
          placeholder="Cerca un elemento per nome o descrizione"
        />
      
        <template
            v-for="item in filteredItems">
            <MenuItem
                :key="'tr-item-'+item._id"

                :menu_id="menu_id"
                :item_id="item._id" />
        </template>
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mdbRow, mdbCol } from 'mdbvue';
import MenuItem from "./MenuItem";

export default {
    name: "MenuItems",
    props: ['menu_id'],
    components: {
        mdbRow, mdbCol,
        MenuItem
    },
    data() {
      return {
          filter: {
            search: '',
          },
          loading: false,
      }
    },
    computed: {...
      mapGetters(['getMenu', 'getItem']),
      filteredItems: function() {
        let items = Object.values(this.getMenu(this.menu_id).items)
        if (!items) return []
        
        return items.filter(item => (
          item.name?.it_it?.toLowerCase().includes(this.filter.search.toLowerCase()) ||
          item.description?.it_it?.toLowerCase().includes(this.filter.search.toLowerCase())
        ))
      }
    },
    methods: {
        ...mapActions(['updateMenu', 'setModalMenuDeleteId', 'setShowModalMenuDelete']),
    }
};
</script>