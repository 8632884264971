<template>
  <div class="container">
    <section>
      <mdb-row>
        <mdb-col lg="12">
          <mdb-card>
            <mdb-card-body>
              <mdb-row v-show="ajax_page_loading">
                <mdb-col md="12" class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </mdb-col>
              </mdb-row>
              <mdb-alert color="danger" v-show="ajax_page_error">
                Impossibile caricare la pagina richiesta. Si prega di verificare che la connessione a Internet funzioni correttamente e riaggiornare la pagina. Se il problema persiste si prega di contattarci.
              </mdb-alert>
              <mdb-row>
                <mdb-col xl="4" lg="5" md="6" sm="12" class="mb-4" v-for="menu in getMenus" v-bind:key="'col-menu-' + menu.menu_short_url">
                  <mdb-card class="h-100">
                    <mdb-card-header>
                      <h5 class="text-truncate">{{ menu.menu_name }}</h5>
                    </mdb-card-header>
                    <mdb-card-body class="text-center">
                      <mdb-row>
                        <mdb-col>
                          <router-link :to="'/menu/' + menu.menu_short_url" class="btn btn-primary w-100">
                              <i class="fas fa-list"></i> Gestione menu
                          </router-link>
                          <br />
                          <button type="button" class="btn w-100"
                            @click="openQRCodeModal(menu.menu_id)">
                            <i class="fas fa-qrcode"></i> Link e Codice QR
                          </button>
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col>
                          <a :href="$static_hostname + '/pdf/' + menu.menu_short_url" target="_blank" class="btn w-100">
                              <i class="fas fa-file-pdf"></i> PDF
                          </a>
                        </mdb-col>
                        <mdb-col>
                          <a :href="$public_menu_hostname + '/' + menu.menu_short_url" target="_blank" class="btn w-100">
                              <i class="fas fa-external-link-alt"></i> Apri
                          </a>
                        </mdb-col>
                      </mdb-row>
                    </mdb-card-body>
                  </mdb-card>
                </mdb-col>
              </mdb-row>
              <div class="text-center w-100">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  @click="setShowModalMenuCreate(true)">
                  <i class="fas fa-plus"></i> Crea un nuovo menu
                </button>
                <button
                  type="button"
                  v-if="deferredPrompt"
                  @click="installPWA"
                  class="btn btn-primary">
                  <i class="fas fa-download"></i> Installa app
                </button>
              </div>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <ModalMenuQRCode v-if="getShowModalMenuQRCode" :menu_id="modalMenuQrCodeMenuId" />
    <ModalMenuAdd v-if="getShowModalMenuCreate" />
    <ModalMenuEdit v-if="getShowModalMenuUpdate" />
    <ModalMenuDelete v-if="getShowModalMenuDelete" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardHeader, mdbAlert } from "mdbvue";

import ModalMenuAdd from "../components/modals/menu/ModalMenuAdd.vue";
import ModalMenuEdit from "../components/modals/menu/ModalMenuEdit.vue";
import ModalMenuDelete from "../components/modals/menu/ModalMenuDelete.vue";
import ModalMenuQRCode from "../components/modals/menu/ModalMenuQRCode.vue";

export default {
  name: "Menus",
  components: {
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbCardHeader,
    mdbAlert,

    ModalMenuAdd,
    ModalMenuEdit,
    ModalMenuDelete,
    ModalMenuQRCode,
  },
  data() {
    return {
      deferredPrompt: null,
      ajax_page_error: false,
      ajax_page_loading: false,
      modalMenuQrCodeMenuId: null,
    };
  },
  created() {
    this.fetchUserToken();
    this.ajax_page_loading = true;
    this.fetchMenus().then(() => {
      this.ajax_page_loading = false;
    }).catch(() => {
      this.ajax_page_loading = false;
      this.ajax_page_error = true;
    });

    // Install PWA
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  computed: mapGetters(['getUserToken', 'getMenus', 'getShowModalMenuCreate', 'getShowModalMenuUpdate', 'getShowModalMenuQRCode', 'getShowModalMenuDelete']),
  methods: {
    ...mapActions(['fetchUserToken', 'fetchMenus', 'addMenu', 'setShowModalMenuCreate', 'setShowModalMenuUpdate', 'setModalMenuUpdateId', 'setShowModalMenuQRCode', 'setModalMenuDeleteId', 'setShowModalMenuDelete']),
    async installPWA() {
      this.deferredPrompt.prompt();
    },
    openQRCodeModal: function (menu_id) {
      this.modalMenuQrCodeMenuId = menu_id;
      this.setShowModalMenuQRCode(true);
    }
  },
};
</script>
