import VueCookies from "vue-cookies";
import { API_HOSTNAME } from '../../constants'
import axios from "axios";

const state = {
    user_token: null,
    authentication_email: ''
};

const getters = {
    getUserToken: state => state.user_token,
    getAuthEmail: state => state.authentication_email,
};

const actions = {
    fetchUserToken({ commit, dispatch }) {
        const userToken = VueCookies.get("token") ?? null;
        commit('SET_USER_TOKEN', userToken)
        
        axios.get(`${API_HOSTNAME}/api/v1/auth/token/check/${userToken}`).then(response => {
            if (response.status !== 200) {
                dispatch('deleteUserToken');
            }
        }).catch(() => {
            dispatch('deleteUserToken');
        });
    },
    setUserToken({ commit }, {userToken, expiration}) {
        // VueCookies.set('token' , userToken, expiration);
        VueCookies.set('token' , userToken, expiration, '/', 'admin.easymenu.cloud', true);
        commit('SET_USER_TOKEN', userToken)
    },
    deleteUserToken({ commit, getters }) {
        VueCookies.remove('token', '/');
        VueCookies.remove('token', '/', 'admin.easymenu.cloud', true);
        axios.post(`${API_HOSTNAME}/api/v1/auth/token/delete`, {'token': getters.getUserToken}).then();
        commit('DELETE_USER_TOKEN')
    },
    setAuthEmail({ commit }, email) {
        commit('SET_AUTH_EMAIL', email)
    },
};

const mutations = {
    SET_USER_TOKEN: (state, userToken) => (state.user_token = userToken),
    DELETE_USER_TOKEN: (state) => (state.user_token = null),
    SET_AUTH_EMAIL: (state, email) => (state.authentication_email = email),
};

export default {
    state,
    getters,
    actions,
    mutations
}