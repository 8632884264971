<template>
  <div>
    <button type="button" class="btn w-100"
      @click="show = true">
      <i class="fas fa-qrcode mr-2"></i> Link e codice QR
    </button>
    <mdb-modal size="lg" @close="show = false" :show="show">
      <mdb-modal-header>
        <mdb-modal-title>Codice QR</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body class="text-center" v-if="qr_code_url != '' && qr_code_shareable_url != ''">
        <mdb-row>
          <mdb-col lg="6" md="12">
            <a class="btn btn-primary mb-3" download="qrcode.png" :href="qr_code_uri">
              <i class="fas fa-download"></i> Scarica codice QR
            </a><br />
            <vue-qr
              :text="qr_code_url"
              :callback="generatedQRURI"
              :size="500"
              style="max-width:250px"
            ></vue-qr>
          </mdb-col>
          <mdb-col lg="6" md="12">
            Oltre al codice QR, è possibile pubblicizzare il link diretto. Questo è consigliato, ad esempio, nel proprio sito web o nella pagina e nei post Facebook.<hr />
            Link: <span class="font-weight-bold">{{ qr_code_shareable_url }}</span>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="none" @click.native="show = false">Chiudi</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueQr from "vue-qr";
import { mdbRow, mdbCol, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn } from 'mdbvue';
export default {
    name: "ReservationFormQRCodeButton",
    props: ['reservationForm'],
  components: {
    mdbRow, mdbCol,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,
    VueQr
  },
  data() {
    return {
      show: false,
      qr_code_uri: "",
      qr_code_url: "",
      qr_code_shareable_url: ""
    }
  },
  created() {
    let short_url = this.reservationForm.short_url;
    this.qr_code_url = `https://reservation.easymenu.cloud/${short_url}?ref=qr`;
    this.qr_code_shareable_url = `https://reservation.easymenu.cloud/${short_url}`;
  },
  computed: mapGetters(['getMenu']),
  methods: {
    ...mapActions(['setShowModalMenuQRCode']),
    generatedQRURI: function (uri) {
      this.qr_code_uri = uri;
    },
  }
};
</script>