<template>
	<mdb-row class="justify-content-center">
		<mdb-col style="max-width:400px">
      <mdb-card>
        <mdb-card-body>
            <p class="h4 text-center">Reset password</p>

            <div class="text-center">
              <div class="spinner-border" role="status" v-if="loading_token">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <div class="alert alert-success" v-if="alerts.success">
              La password è stata modificata correttamente. Cliccare il pulsante sotto per accedere al pannello di amministrazione.
              <p class="text-center">
                <router-link to="/auth" class="btn btn-primary">
                    Accedi
                </router-link>
              </p>
            </div>

            <div class="alert alert-warning" v-if="alerts.token_invalid">
              Spiacenti, il link non è valido.<br />
              Vi preghiamo di contattarci via email a <a href="mailto:info@easymenu.cloud">info@easymenu.cloud</a>.
            </div>

            
            <form @submit="doPasswordReset" v-if="!loading_token && !alerts.token_invalid && !alerts.success">
              <div class="alert alert-info">
              Inserire una nuova password.
              </div>
              <div class="grey-text">
                <mdb-input
                  label="Password"
                  icon="lock"
                  group
                  type="password"
                  validate
                  ref="password"
                  v-model="form.password"
                />
                <mdb-input
                  label="Conferma password"
                  icon="lock"
                  group
                  type="password"
                  validate
                  v-model="form.password_check"
                />
              </div>
              <div class="text-center py-4 mt-3">
                <mdb-btn color="primary" type="submit" :disabled="loading_reset">
                  <span
                    class="spinner-border spinner-border-sm mr-4"
                    role="status"
                    aria-hidden="true"
                    v-if="loading_reset"
                  ></span>
                  Reset password
                </mdb-btn>
              </div>
            </form>

        </mdb-card-body>
      </mdb-card>
		</mdb-col>
	</mdb-row>
</template>


<script>
import { mdbRow, mdbCol, mdbCard, mdbCardBody, mdbInput, mdbBtn } from "mdbvue";
import axios from 'axios';

export default {
	name: "PasswordReset",
	components: {
		mdbRow,
		mdbCol,
		mdbCard,
		mdbCardBody,
		mdbInput,
		mdbBtn,
	},
	data() {
		return {
			loading_token: true,
      loading_reset: false,

      alerts: {
        success: false,
        token_invalid: false
      },
      
      form: {
        password: '',
        password_check: ''
      }
		};
	},
  created() {
    axios
      .post(`${this.$api_hostname}/api/v1/auth/password/reset_token_check`, {
        token: this.$route.params.token,
      })
      .then((response) => {
        this.loading_token = false;
        if (response.status != 200) {
          this.alerts.token_invalid = true;
        }
      })
      .catch(() => {
        this.loading_token = false;
        this.alerts.token_invalid = true;
      });
  },
  methods: {
    doPasswordReset: function(event) {
      event.preventDefault();

      if (this.form.password !== this.form.password_check) {
				alert("Attenzione, le password non coincidono!");
      } else if (this.form.password.length < 6) {
				alert("Attenzione, la password deve contenere almeno 6 caratteri");
      } else if (this.form.password.length > 50) {
				alert("Attenzione, la password deve contenere massimo 50 caratteri");
			} else {
        this.loading_reset = true;
        axios
          .post(`${this.$api_hostname}/api/v1/auth/password/reset`, {
            token: this.$route.params.token,
            password: this.form.password,
          })
          .then((response) => {
            this.loading_reset = false;
            if (response.status == 200) {
              this.alerts.success = true;
            } else {
              alert("Error");
            }
          })
          .catch((err) => {
            this.loading_reset = false;
            alert(
              "Errore. Si prega di controllare la connessione ad Internet e di provare ad aggiornare la pagina. Errore: " +
                err
            );
          });
      }
    }
  }
};
</script>