<template>
  <form @submit="submitForm">
    <mdb-row>
      <mdb-col lg="6" md="12">
        <label for="model_add_menu_input_name" class="grey-text">Nome pubblico</label>
        <input
          type="text"
          id="model_add_menu_input_name"
          class="form-control"
          v-model="form.name" />
      </mdb-col>
      <mdb-col lg="6" md="12">
        <mdb-row>
          <mdb-col sm="8" col="12">
            <label for="model_add_menu_input_image" class="grey-text">Immagine</label>
            <input
              type="file"
              accept="image/*"
              @change="form.image = $event.target.files[0]"
              id="model_add_menu_input_image"
              class="form-control"
            />
          </mdb-col>
          <mdb-col sm="4" col="12" class="text-center">
            <img v-if="form.image_id && form.image !== false" :src="$static_hostname + '/images/' + form.image_id" style="max-width:50%;max-height:100px" /><br />
            <mdb-btn v-if="form.image_id && form.image !== false" size="sm" outline="danger" @click="form.image = false">Elimina immagine</mdb-btn>
            <mdb-btn v-if="form.image === false" outline="danger" size="sm" @click="form.image = null">Annulla eliminazione immagine</mdb-btn>
          </mdb-col>
        </mdb-row>
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col>
        <mdb-btn type="submit" color="primary" :disabled="loading">
            <span class="spinner-border spinner-border-sm mr-4" role="status" aria-hidden="true" v-if="loading"></span>
            Salva
        </mdb-btn>
      </mdb-col>
    </mdb-row>
    <mdb-row>
      <mdb-col class="text-center mt-5 pt-5">
        <hr class="mt-5" />
        <button type="button" class="btn btn-sm btn-danger" @click="setModalMenuDeleteId(menu_id); setShowModalMenuDelete(true)">
          <i class="fas fa-trash-alt mr-4"></i> Elimina questo menu
        </button>
      </mdb-col>
    </mdb-row>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mdbRow, mdbCol, mdbBtn } from 'mdbvue';
export default {
    name: "MenuGeneralSettings",
    props: ['menu_id'],
    components: {
        mdbRow, mdbCol,
        mdbBtn
    },
    data() {
      return {
          loading: false,
          form: {
              name: '',
              image: null,
              image_id: null
          }
      }
    },
    created() {
      this.updateForm();
    },
    computed: mapGetters(['getMenu']),
    methods: {
        ...mapActions(['updateMenu', 'setModalMenuDeleteId', 'setShowModalMenuDelete']),
        updateForm: function() {
          this.form.name = this.getMenu(this.menu_id).menu_name;
          this.form.image_id = this.getMenu(this.menu_id).menu_image;
          this.form.image = null;
        },
        submitForm: async function(e) {
            e.preventDefault();
            this.loading = true;

            try{
              await this.updateMenu({
                _id: this.menu_id,
                short_url: this.getMenu(this.menu_id).menu_short_url,
                name: this.form.name,
                image: this.form.image
              })
              this.updateForm();
            } catch (e) {
              alert('Si è verificato un errore durante il salvataggio delle impostazioni o durante il caricamento dell\'immagine. Si prega di riprovare. Se il problema persiste vi invitiamo a contattarci.')
              console.log(e)
            }

            this.loading = false;
        }
    }
};
</script>