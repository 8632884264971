<template>
  <tr>
    <td>{{ reservation.datetime.slice(10) }}</td>
    <td>{{ reservation.number_of_people }}</td>
    <td>
      {{ reservation.contact.name }}<br />
      (+{{ reservation.contact.telephone.prefix }}) {{ formatTelephoneNumber(reservation.contact.telephone.number) }}<br />
      {{ reservation.contact.email }}
      <i v-if="reservation.contact.marketing_consent"><br />Consenso marketing fornito</i>
    </td>
    <td>{{ reservation.notes }}</td>
    <td>{{ dateToString(new Date(reservation.created_at)) }}</td>
  </tr>
</template>

<script>
export default {
    name: "ReservationTr",
    props: ['reservation'],
    methods: {
      dateToString: function(date) {
        let year = date.getFullYear();
        let month = date.getMonth().toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let hour = date.getHours().toString().padStart(2, '0');
        let minute = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year} alle ${hour}:${minute}`;
      },
      formatTelephoneNumber: function(telephone_number) {
            return String(telephone_number).replace(/^(.{3})(.{3})(.*)$/, "$1 $2 $3");
      }
    }
};
</script>