import Vue from 'vue'
import Vuex from 'vuex'

import language from './modules/language/store'
import authentication from './modules/authentication/store'
import menu from './modules/menu/store'
import reservation from './modules/reservation/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    language,
    authentication,
    menu,
    reservation
  },
  state: {
    // Modal menu
    show_modal_menu_qr_code: false,

    show_modal_menu_create: false,

    show_modal_menu_update: false,
    modal_menu_update_id: null,

    show_modal_menu_delete: false,
    modal_menu_delete_id: null,

    // Modal category
    show_modal_category_add: false,

    show_modal_category_edit: false,
    modal_category_edit_id: null,

    show_modal_category_delete: false,
    modal_category_delete_id: null,

    // Modal item
    show_modal_item_create: false,
    category_of_modal_item_create: null,

    show_modal_item_update: false,
    modal_item_update_id: null,
    
    show_modal_item_delete: false,
    modal_item_delete_id: null,
  },
  getters: {
    // Modal menu
    getShowModalMenuQRCode: (state) => state.show_modal_menu_qr_code,
    getShowModalMenuCreate: (state) => state.show_modal_menu_create,
    
    getShowModalMenuUpdate: (state) => state.show_modal_menu_update,
    getModalMenuUpdateId: (state) => state.modal_menu_update_id,
    
    getShowModalMenuDelete: (state) => state.show_modal_menu_delete,
    getModalMenuDeleteId: (state) => state.modal_menu_delete_id,

    // Modal category
    getShowModalCategoryAdd: (state) => state.show_modal_category_add,
    
    getShowModalCategoryEdit: (state) => state.show_modal_category_edit,
    getModalCategoryEditId: (state) => state.modal_category_edit_id,
    
    getShowModalCategoryDelete: (state) => state.show_modal_category_delete,
    getModalCategoryDeleteId: (state) => state.modal_category_delete_id,

    // Modal item
    getShowModalItemCreate: (state) => state.show_modal_item_create,
    getCategoryOfModalItemCreate: (state) => state.category_of_modal_item_create,
    
    getShowModalItemUpdate: (state) => state.show_modal_item_update,
    getModalItemUpdateId: (state) => state.modal_item_update_id,
    
    getShowModalItemDelete: (state) => state.show_modal_item_delete,
    getModalItemDeleteId: (state) => state.modal_item_delete_id,

  },
  actions: {
    // Modal menu
    setShowModalMenuQRCode: ({ commit }, show) => commit('SET_SHOW_MODAL_MENU_QR_CODE', show),
    setShowModalMenuCreate: ({ commit }, show) => commit('SET_SHOW_MODAL_MENU_CREATE', show),
    
    setShowModalMenuUpdate: ({ commit }, show) => commit('SET_SHOW_MODAL_MENU_UPDATE', show),
    setModalMenuUpdateId: ({ commit }, id) => commit('SET_MODAL_MENU_UPDATE_ID', id),
    
    setShowModalMenuDelete: ({ commit }, show) => commit('SET_SHOW_MODAL_MENU_DELETE', show),
    setModalMenuDeleteId: ({ commit }, id) => commit('SET_MODAL_MENU_DELETE_ID', id),

    // Modal category
    setShowModalCategoryAdd: ({ commit }, show) => commit('SET_SHOW_MODAL_CATEGORY_ADD', show),
    
    setShowModalCategoryEdit: ({ commit }, show) => commit('SET_SHOW_MODAL_CATEGORY_EDIT', show),
    setModalCategoryEditId: ({ commit }, id) => commit('SET_MODAL_CATEGORY_EDIT_ID', id),
    
    setShowModalCategoryDelete: ({ commit }, show) => commit('SET_SHOW_MODAL_CATEGORY_DELETE', show),
    setModalCategoryDeleteId: ({ commit }, id) => commit('SET_MODAL_CATEGORY_DELETE_ID', id),

    // Modal item
    setShowModalItemCreate: ({ commit }, {show, category_id}) => {
      commit('SET_CATEGORY_OF_MODAL_ITEM_CREATE', category_id ?? '')
      commit('SET_SHOW_MODAL_ITEM_CREATE', show)
    },
    
    setShowModalItemUpdate: ({ commit }, show) => commit('SET_SHOW_MODAL_ITEM_UPDATE', show),
    setModalItemUpdateId: ({ commit }, id) => commit('SET_MODAL_ITEM_UPDATE_ID', id),
    
    setShowModalItemDelete: ({ commit }, show) => commit('SET_SHOW_MODAL_ITEM_DELETE', show),
    setModalItemDeleteId: ({ commit }, id) => commit('SET_MODAL_ITEM_DELETE_ID', id),
  },
  mutations: {
    // Modal menu
    SET_SHOW_MODAL_MENU_QR_CODE: (state, show) => (state.show_modal_menu_qr_code = show),
    SET_SHOW_MODAL_MENU_CREATE: (state, show) => (state.show_modal_menu_create = show),
    
    SET_SHOW_MODAL_MENU_UPDATE: (state, show) => (state.show_modal_menu_update = show),
    SET_MODAL_MENU_UPDATE_ID: (state, id) => (state.modal_menu_update_id = id),
    
    SET_SHOW_MODAL_MENU_DELETE: (state, show) => (state.show_modal_menu_delete = show),
    SET_MODAL_MENU_DELETE_ID: (state, id) => (state.modal_menu_delete_id = id),

    // Modal category
    SET_SHOW_MODAL_CATEGORY_ADD: (state, show) => (state.show_modal_category_add = show),
    
    SET_SHOW_MODAL_CATEGORY_EDIT: (state, show) => (state.show_modal_category_edit = show),
    SET_MODAL_CATEGORY_EDIT_ID: (state, id) => (state.modal_category_edit_id = id),
    
    SET_SHOW_MODAL_CATEGORY_DELETE: (state, show) => (state.show_modal_category_delete = show),
    SET_MODAL_CATEGORY_DELETE_ID: (state, id) => (state.modal_category_delete_id = id),

    // Modal item
    SET_SHOW_MODAL_ITEM_CREATE: (state, show) => (state.show_modal_item_create = show),
    SET_CATEGORY_OF_MODAL_ITEM_CREATE: (state, category) => (state.category_of_modal_item_create = category),
    
    SET_SHOW_MODAL_ITEM_UPDATE: (state, show) => (state.show_modal_item_update = show),
    SET_MODAL_ITEM_UPDATE_ID: (state, id) => (state.modal_item_update_id = id),
    
    SET_SHOW_MODAL_ITEM_DELETE: (state, show) => (state.show_modal_item_delete = show),
    SET_MODAL_ITEM_DELETE_ID: (state, id) => (state.modal_item_delete_id = id),
  }
})
