import { API_HOSTNAME } from '../../constants'
import axios from "axios";

const state = {
    reservation_forms: {},
    last_update: null
};

const getters = {
    getReservationForms: state => state.reservation_forms,
    getReservationFormByUrl: state => (url) => {
        for (let reservationForm of Object.values(state.reservation_forms)) {
            if (reservationForm.short_url == url) {
                return reservationForm;
            }
        }
        return null;
    },
    getLastUpdateReservationForms: state => state.last_update
};

const actions = {
    // Reservation form
    async fetchReservationForms({ commit, getters, rootGetters }, async = true) {
        if (getters.getReservationForms.length > 0) {
            if (async) {
                const request = axios.get(`${API_HOSTNAME}/api/v1/admin/reservation_forms`, { headers: { Token: rootGetters.getUserToken } });
                request.then(response => {
                    let reservation_forms = response.data.reduce((a, x) => ({...a, [x._id]: x}), {})
                    commit('UPDATE_RESERVATION_FORMS', reservation_forms);
                    commit('UPDATE_LAST_UPDATE_RESERVATION_FORMS', new Date());
                });
            }
        } else {
            const response = await axios.get(`${API_HOSTNAME}/api/v1/admin/reservation_forms`, { headers: { Token: rootGetters.getUserToken } });
            let reservation_forms = response.data.reduce((a, x) => ({...a, [x._id]: x}), {})
            commit('UPDATE_RESERVATION_FORMS', reservation_forms);
            commit('UPDATE_LAST_UPDATE_RESERVATION_FORMS', new Date());
        }
    },
    async createReservationForm({ commit, rootGetters }, reservationForm) {

        // Deep copy object to avoid editing the frontend form
        reservationForm = JSON.parse(JSON.stringify(reservationForm));

        // For each generic rule
        for (let i=0; i<reservationForm.generic_rules.length; ++i) {

            let new_days_of_the_week = [];

            // For each day of the week
            for (let dow=0; dow<7; ++dow) {

                // If the rule is for this day of the week
                if (reservationForm.generic_rules[i].days_of_the_week[dow]) {
                    // Add the day of the week to the final object
                    new_days_of_the_week.push(dow);
                }
            }
        
            // Use the new days of the week format
            reservationForm.generic_rules[i].days_of_the_week = new_days_of_the_week;

            for (let j=0; j<reservationForm.generic_rules[i].times.length; ++j) {
                let time = reservationForm.generic_rules[i].times[j]
                // Set max_total_number_of_people for each time
                reservationForm.generic_rules[i].times[j].max_total_number_of_people = (time.set_max_total_number_of_people) ? time.max_total_number_of_people : null;
            }
        }
        
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/reservation_form`,
            reservationForm,
            { headers: { Token: rootGetters.getUserToken } }
        );
        
        if (response?.data?._id) {
            reservationForm._id = response.data._id;
            reservationForm.short_url = response.data.short_url;
            commit('CREATE_RESERVATION_FORM', reservationForm);
        }

        return response;
    },
    async updateReservationForm({ commit, rootGetters }, reservationForm) {

        // Deep copy object to avoid editing the frontend form
        reservationForm = JSON.parse(JSON.stringify(reservationForm));

        // For each generic rule
        for (let i=0; i<reservationForm.generic_rules.length; ++i) {

            let new_days_of_the_week = [];

            // For each day of the week
            for (let dow=0; dow<7; ++dow) {

                // If the rule is for this day of the week
                if (reservationForm.generic_rules[i].days_of_the_week[dow]) {
                    // Add the day of the week to the final object
                    new_days_of_the_week.push(dow);
                }
            }
        
            // Use the new days of the week format
            reservationForm.generic_rules[i].days_of_the_week = new_days_of_the_week;
        }
        
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/reservation_form/${reservationForm._id}`,
            reservationForm,
            { headers: { Token: rootGetters.getUserToken } }
        );
        
        if (response?.status == 200) {
            commit('UPDATE_RESERVATION_FORMS', {[reservationForm._id]: reservationForm});
        }

        return response;
    },
    async deleteReservationForm({ commit, rootGetters }, reservationFormId) {
        
        const response = await axios.delete(
            `${API_HOSTNAME}/api/v1/admin/reservation_form/${reservationFormId}`,
            { headers: { Token: rootGetters.getUserToken } }
        );
        
        if (response?.status == 200) {
            commit('DELETE_RESERVATION_FORM', reservationFormId);
        }

        return response;
    },
};

const mutations = {
    CREATE_RESERVATION_FORM: (state, reservation_form) => {
        state.reservation_forms[reservation_form._id] = reservation_form;
        state.reservation_forms = {...state.reservation_forms}
    },
    UPDATE_RESERVATION_FORMS: (state, reservation_forms) => {
        for (const [reservation_form_id, reservation_form] of Object.entries(reservation_forms)) {
            if ( ! (reservation_form_id in state.reservation_forms) ) {
                state.reservation_forms[reservation_form_id] = {};
            }
            state.reservation_forms[reservation_form_id] = {
                // Take existing object
                ...state.reservation_forms[reservation_form_id],

                // Update only keys in reservation_forms (argument)
                ...reservation_form
            }
        }
        
        state.reservation_forms = {...state.reservation_forms}
    },
    DELETE_RESERVATION_FORM: (state, reservation_form_id) => {
        delete state.reservation_forms[reservation_form_id];
        state.reservation_forms = {...state.reservation_forms}
    },
    UPDATE_LAST_UPDATE_RESERVATION_FORMS: (state, date) => (state.last_update = date),
};

export default {
    state,
    getters,
    actions,
    mutations
}