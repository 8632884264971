import { API_HOSTNAME, STATIC_HOSTNAME } from '../../constants'
import axios from "axios";

const state = {
    /**
     *  menus: {
     *      menu_id: {
     *          name: "",
     *          categories: { category_id: { ... } },
     *          items: { item_id: { ... } }
     *          menu: { sub_categories: [ category_id ], items: [ item_id ] }
     *      }
     *  }
     */
    menus: null,
    current_menu_id: null,
    current_menu: null,
    current_menu_menu: null,
};

const getters = {
    // Menu
    getMenus: state => Object.values(state.menus ?? {}),
    getMenu: (state) => (id) => state.menus[id],
    getCurrentMenu: state => state.current_menu,
    getCurrentMenuMenu: state => state.current_menu_menu,
    getMenuByUrl: (state) => (url) => Object.values(state.menus ?? {}).find(menu => menu.menu_short_url == url),

    // Category
    getCategory: (state, getters) => ({menu_id, category_id}) => getters.getMenu(menu_id)?.categories[category_id],
    getCategories: state => ({menu_id}) => Object.values(state.menus[menu_id]?.categories ?? {}),

    // Item
    getItem: (state, getters) => ({menu_id, item_id}) => getters.getMenu(menu_id)?.items[item_id],
    getItems: (state) => ({menu_id}) => Object.values(state.menus[menu_id]?.items ?? {}),
    getItemCategories: (state) => ({menu_id, item_id}) => Object.values(state.menus[menu_id].categories).filter(category => {
        return (category.items.includes(item_id))
    })
};

const actions = {
    // Menu
    setCurrentMenu: ({ commit }, currentMenuId) => commit('SET_CURRENT_MENU_ID', currentMenuId),
    async fetchMenus({ commit, getters, rootGetters }, async = true) {
        if (getters.getMenus.length > 0) {
            if (async) {
                const request = axios.get(`${API_HOSTNAME}/api/v1/admin/menu`, { headers: { Token: rootGetters.getUserToken } });
                request.then(response => {
                    let menus = response.data.reduce((a, x) => ({...a, [x.menu_id]: x}), {})
                    commit('UPDATE_MENUS', menus);
                });
            }
        } else {
            const response = await axios.get(`${API_HOSTNAME}/api/v1/admin/menu`, { headers: { Token: rootGetters.getUserToken } });
            let menus = response.data.reduce((a, x) => ({...a, [x.menu_id]: x}), {})
            commit('SET_MENUS', menus);
        }
    },
    async fetchMenuByUrl({ dispatch, commit, getters, rootGetters }, url) {
        let menu = getters.getMenuByUrl(url);
        if (menu == null) {
            await dispatch('fetchMenus', false);
        }
        menu = getters.getMenuByUrl(url);
        if (menu.menu) {
            axios.get(`${API_HOSTNAME}/api/v1/admin/menu/${menu.menu_short_url}`, {headers: {'Token': rootGetters.getUserToken}})
            .then(response => {
                menu['menu'] = { 'sub_categories': response.data.food_categories.map(x => x.food_category_id) };
                menu['categories'] = response.data.categories.reduce((a, x) => ({...a, [x._id]: x}), {})
                menu['items'] = response.data.items.reduce((a, x) => ({...a, [x._id]: x}), {})
                commit('UPDATE_MENU', {id: menu.menu_id, menu:menu});
            })
        } else {
            const response = await axios.get(`${API_HOSTNAME}/api/v1/admin/menu/${menu.menu_short_url}`, {headers: {'Token': rootGetters.getUserToken}});
            menu['menu'] = { 'sub_categories': response.data.food_categories.map(x => x.food_category_id) };
            menu['categories'] = response.data.categories.reduce((a, x) => ({...a, [x._id]: x}), {})
            menu['items'] = response.data.items.reduce((a, x) => ({...a, [x._id]: x}), {})  
            commit('UPDATE_MENU', {id: menu.menu_id, menu:menu});
        }
        return menu;
    },
    async createMenu({ commit, rootGetters }, menu) {
        // menu.name
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/menu`,
            { menu_name: menu.name },
            { headers: { Token: rootGetters.getUserToken } }
        )
        if (response.status == 200) {
            commit('CREATE_MENU', {
                menu_id: response.data.menu_id,
                menu_short_url: response.data.menu_short_url,
                menu_name: menu.name
            });
        }
        return response
    },
    async updateMenu({ commit, rootGetters }, menu) {
        
        // Remove image: it will be uploaded later
        const image = menu.image;
        menu.image = null;

        // Deep copy object to avoid editing the frontend form
        // After getting the image (which is not JSONable)
        menu = JSON.parse(JSON.stringify(menu));

        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/menu/${menu.short_url}`,
            { menu_name: menu.name },
            { headers: { Token: rootGetters.getUserToken } }
        )
        if (response.status == 200) {
            commit('UPDATE_MENU', {
                id: menu._id,
                menu: {
                    menu_id: menu._id,
                    menu_short_url: menu.short_url,
                    menu_name: menu.name
                }
            });

            let additionalPromises = [];

            // Upload image    
            if (image !== null) {

                // Remove the image
                if (image === false) {
                    additionalPromises.push(axios.post(
                        `${API_HOSTNAME}/api/v1/admin/menu/delete_image`,
                        { 'menu_id': menu._id },
                        {headers: {'Token': rootGetters.getUserToken}}
                    ))
                } else {
                    let data = new FormData();
                    data.append('image', image);
                    data.append('menu_id', menu._id);
                    additionalPromises.push(axios.post(
                        `${STATIC_HOSTNAME}/images/upload`,
                        data,
                        {headers: {'Content-Type': 'image/png', 'Token': rootGetters.getUserToken}}
                    ))
                }
            }

            await Promise.all(additionalPromises);
            let additionalPromisesResponses = await Promise.all(additionalPromises);
            if (image !== null) {
                commit('UPDATE_MENU', {
                    id: menu._id,
                    menu: {
                        menu_id: menu._id,
                        menu_image: (image === false) ? null : additionalPromisesResponses[0].data.filename
                    }
                });
            }

        }
        return response
    },
    async deleteMenu({ commit, getters, rootGetters }, menu_id) {
        const response = await axios.delete(
            `${API_HOSTNAME}/api/v1/admin/menu/${getters.getMenu(menu_id).menu_short_url}`,
            { headers: {'Token': rootGetters.getUserToken} }
        )
        if (response.status == 200) {
            commit('DELETE_MENU', menu_id);
        }
        return response
    },

    // Category
    async addCategory({ commit, rootGetters }, {menu_id, category}) {
        // menu.name
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food_category/add`, {
            'menu_id': menu_id,
            'name': category.name,
            'description': category.description,
            'show': category.show,
            'language_code': 'it_it'
        }, {headers: {'Token': rootGetters.getUserToken}})
        if (response.status == 200) {
            commit('ADD_CATEGORY', {
                menu_id: menu_id,
                category: {
                    _id: response.data._id,
                    name: category.name,
                    description: category.description,
                    show: category.show,
                    'foods': []
                }
            });
        }
        return response
    },
    async updateCategory({ commit, rootGetters }, {menu_id, category}) {
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food_category/update`, {
            menu_id,
            _id: category._id,
            name: category.name,
            description: category.description,
            show: category.show,
            language_code: 'it_it'
        }, {headers: {'Token': rootGetters.getUserToken}})
        if (response.status == 200) {
            commit('UPDATE_CATEGORY', {
                menu_id: menu_id,
                category: {
                    _id: category._id,
                    name: { 'it_it': category.name },
                    description: { 'it_it': category.description },
                    show: category.show,
                }
            });
        }
        return response
    },
    async updateCategoriesSortingPosition({ commit, getters, rootGetters }, {menu_id, parent_category_id, sortingPositions, finalArray}) {
        // parent_category_id is null if root (menu itself)

        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food_category/update_sorting_position`,
            { 'food_category_sorting_positions': sortingPositions, menu_id, parent_category_id, 'sorted_array': finalArray },
            { headers: {'Token': rootGetters.getUserToken} }
        )
        if (response.status == 200) {
            if (parent_category_id) {
                // TODO: If resorting categories children of a category
            } else {
                // If resorting categories children of the menu
                commit('UPDATE_MENU', {
                    id: menu_id,
                    menu: { _id: menu_id, menu: {
                        ...getters.getMenu(menu_id).menu,
                        sub_categories: finalArray
                    } }
                });
            }
        }
        return response
    },
    async deleteCategory({ commit, rootGetters }, {menu_id, category_id}) {
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food_category/delete`,
            { 'menu_id': menu_id, 'food_category_id': category_id },
            {headers: {'Token': rootGetters.getUserToken}})
        if (response.status == 200) {
            commit('DELETE_CATEGORY', {
                menu_id: menu_id,
                category_id: category_id
            });
        }
        return response
    },

    // Item
    async addItem({ commit, dispatch, getters, rootGetters }, {menu_id, item}) {
        
        // Remove image: it will be uploaded later
        const image = item.image;
        item.image = null;

        // Deep copy object to avoid editing the frontend form
        // After getting the image (which is not JSONable)
        item = JSON.parse(JSON.stringify(item));

        // Remove prices: they will be added later via specific API
        const prices = [...item.prices];
        item.prices = [];
        

        item.name = { 'it_it': item.name };
        item.description = { 'it_it': item.description };
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food/add`, {
            'menu_short_url': getters.getMenu(menu_id).menu_short_url,
            'food_name': item.name['it_it'],
            'food_description': item.description['it_it'],
            'show': item.show,
            'food_category_id': item.category_id
        }, {headers: {'Token': rootGetters.getUserToken}})
        if (response.status == 200) {
            item._id = response.data.food_id;
            commit('ADD_ITEM', {
                menu_id: menu_id,
                item: item
            });

            let additionalPromises = [];

            // Upload image
            if (image) {
                let data = new FormData();
                data.append('image', image);
                data.append('food_id', item._id);
                additionalPromises.push(axios.post(
                    `${STATIC_HOSTNAME}/images/upload`,
                    data,
                    {headers: {'Content-Type': 'image/png', 'Token': rootGetters.getUserToken}}
                ))
            }

            // Add prices
            for (let price of prices) {
                price.name = { 'it_it': price.name };
                additionalPromises.push(dispatch('addPrice', {menu_id, item_id: item._id, price}));
            }
            let additionalPromisesResponses = await Promise.all(additionalPromises);
            if (image) {
                commit('UPDATE_ITEM', {
                    menu_id,
                    item: {
                        _id: item._id,
                        image: additionalPromisesResponses[0].data.filename
                    }
                });
            }
        }
        return response
    },
    async updateItem({ commit, dispatch, rootGetters }, {menu_id, item}) {
        
        // Remove image: it will be uploaded later
        const image = item.image;
        item.image = null;

        // Deep copy object to avoid editing the frontend form
        // After getting the image (which is not JSONable)
        item = JSON.parse(JSON.stringify(item));

        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food/update`, {
            'menu_id': menu_id,
            'food_id': item._id,
            // 'food_category_id': this.modal_food_data.food_category_id,
            'food_name': item.name,
            'food_description': item.description,
            'show': item.show,
            'language_code': 'it_it'
        }, {headers: {'Token': rootGetters.getUserToken}})
        if (response.status == 200) {
            const item_id = item._id;
            commit('UPDATE_ITEM', {
                menu_id: menu_id,
                item: {
                    _id: item_id,
                    name: { 'it_it': item.name },
                    description: { 'it_it': item.description },
                    show: item.show
                }
            });

            let additionalPromises = [];

            // Upload image    
            if (image) {
                let data = new FormData();
                data.append('image', image);
                data.append('food_id', item._id);
                additionalPromises.push(axios.post(
                    `${STATIC_HOSTNAME}/images/upload`,
                    data,
                    {headers: {'Content-Type': 'image/png', 'Token': rootGetters.getUserToken}}
                ))
            }

            // Prices

            // Create new prices
            for (let price of item.prices.create) {
                price = {...price}
                price.name = { 'it_it': price.name };
                additionalPromises.push(dispatch('addPrice', {menu_id, item_id, price}));
            }

            // Update existing prices
            for (let price of item.prices.update) {
                price = {...price}
                price.name = { 'it_it': price.name };
                additionalPromises.push(dispatch('updatePrice', {menu_id, item_id, price}));
            }

            // Delete existing prices
            for (let price_id of item.prices.delete) {
                additionalPromises.push(dispatch('deletePrice', {menu_id, item_id, price_id}));
            }

            await Promise.all(additionalPromises);
            let additionalPromisesResponses = await Promise.all(additionalPromises);
            if (image) {
                commit('UPDATE_ITEM', {
                    menu_id,
                    item: {
                        _id: item._id,
                        image: additionalPromisesResponses[0].data.filename
                    }
                });
            }
    
        }
        return response
    },
    async updateItemsSortingPosition({ commit, rootGetters }, {menu_id, category_id, sortingPositions, finalArray}) {
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food/update_sorting_position`,
            { 'food_sorting_positions': sortingPositions, category_id, 'sorted_array': finalArray },
            { headers: {'Token': rootGetters.getUserToken} }
        )
        if (response.status == 200) {
            commit('UPDATE_CATEGORY', {
                menu_id,
                category: { _id: category_id, items: finalArray }
            });
        }
        return response
    },
    async deleteItemImage({ commit, rootGetters }, {menu_id, item_id}) {
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food/delete_image`, {
            'menu_id': menu_id,
            'food_id': item_id,
        }, {headers: {'Token': rootGetters.getUserToken}})
        if (response.status == 200) {
            commit('UPDATE_ITEM', {
                menu_id: menu_id,
                item: {
                    _id: item_id,
                    image: null
                }
            });
    
        }
        return response
    },
    async deleteItem({ commit, rootGetters }, {menu_id, item_id}) {
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food/delete`,
            { 'food_id': item_id },
            {headers: {'Token': rootGetters.getUserToken}}
        )
        if (response.status == 200) {
            commit('DELETE_ITEM', {
                menu_id: menu_id,
                item_id: item_id
            });
        }
        return response
    },

    // Price
    async addPrice({ commit, rootGetters }, {menu_id, item_id, price}) {
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food_option/add`, {
            'food_id': item_id,
            'food_option_name': price.name['it_it'],
            'food_option_price': price.price,
            'food_option_available': price.available,
            'language_code': 'it_it'
        }, {headers: {'Token': rootGetters.getUserToken}})
        if (response.status == 200) {
            price._id = response.data.food_option_id;
            commit('ADD_PRICE', {
                menu_id: menu_id,
                item_id: item_id,
                price: price
            });
        }
        return response
    },
    async updatePrice({ commit, rootGetters }, {menu_id, item_id, price}) {
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food_option/update`, {
                menu_id,
                item_id,
                'food_option_id': price._id,
                'food_id': item_id,
                'food_option_name': price.name['it_it'],
                'food_option_price': price.price,
                'food_option_available': price.available,
                'language_code': 'it_it'
        }, {headers: {'Token': rootGetters.getUserToken}})
        if (response.status == 200) {
            commit('UPDATE_PRICE', {
                menu_id,
                item_id,
                price
            });
        }
        return response
    },
    async updatePriceAvailability({ commit, rootGetters }, {menu_id, item_id, price_id, available}) {
        const response = await axios.get(
            `${API_HOSTNAME}/api/v1/admin/food_option/change_availability?food_option_id=${price_id}&available=${available}`,
            { headers: {'Token': rootGetters.getUserToken} }
        )
        if (response.status == 200) {
            commit('UPDATE_PRICE', {
                menu_id,
                item_id,
                price: {
                    _id: price_id,
                    available
                }
            });
        }
        return response
    },
    async updatePricesSortingPosition({ commit, rootGetters }, {menu_id, item_id, sortingPositions, finalArray}) {
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food_option/update_sorting_position`,
            { 'food_option_sorting_positions': sortingPositions },
            { headers: {'Token': rootGetters.getUserToken} }
        )
        if (response.status == 200) {
            commit('UPDATE_ITEM', {
                menu_id,
                item: { _id: item_id, prices: finalArray }
            });
        }
        return response
    },
    async deletePrice({ commit, rootGetters }, {menu_id, item_id, price_id}) {
        const response = await axios.post(
            `${API_HOSTNAME}/api/v1/admin/food_option/delete`, {
            menu_id,
            item_id,
            'food_option_id': price_id,
        }, {headers: {'Token': rootGetters.getUserToken}})
        if (response.status == 200) {
            commit('DELETE_PRICE', {
                menu_id,
                item_id,
                price_id
            });
        }
        return response
    },
};

const mutations = {
    // Menu
    SET_MENUS: (state, menus) => (state.menus = menus),
    UPDATE_MENUS: (state, menus) => {
        let new_menus = {};
        for (const [menu_id, menu] of Object.entries(menus)) {
            let old_menu = state.menus[menu_id]
            for (const [key, value] of Object.entries(menu)) {
                old_menu[key] = value;
            }
            new_menus[menu_id] = old_menu;
        }
        if (state.current_menu_id) {
            state.current_menu = menus[state.current_menu_id]
            state.current_menu_menu = menus[state.current_menu_id].menu
        }
    },
    SET_CURRENT_MENU_ID: (state, currentMenuId) => {
        state.current_menu_id = currentMenuId
        if (currentMenuId) {
            state.current_menu = state.menus[currentMenuId]
            state.current_menu_menu = state.current_menu.menu
        } else {
            state.current_menu = null;
            state.current_menu_menu = null;
        }
    },
    CREATE_MENU: (state, menu) => (state.menus = { ...state.menus, [menu.menu_id]: menu } ),
    UPDATE_MENU: (state, {id, menu}) => {
        state.menus[id] = {
            ...state.menus[id],
            ...menu
        }
        
        state.menus = {...state.menus};
        if (state.current_menu_id == id) {
            state.current_menu = state.menus[id]
            state.current_menu_menu = state.menus[id].menu
        }
    },
    DELETE_MENU: (state, menu_id) => {
        delete state.menus[menu_id];
        state.menus = {...state.menus};

        if (state.current_menu_id == menu_id) {
            state.current_menu = null
            state.current_menu_menu = null
        }
    },

    // Category
    ADD_CATEGORY: (state, {menu_id, category}) => {
        state.menus[menu_id].categories[category._id] = {
            _id: category._id,
            name: { 'it_it': category.name },
            description: { 'it_it': category.description },
            show: category.show,
            image: null,
            sorting_position: null,
            items: [],
            sub_categories: []
        };
        state.menus[menu_id].menu.sub_categories.push(category._id);
        state.menus = {...state.menus};
    },
    UPDATE_CATEGORY: (state, {menu_id, category}) => {
        // Create a new dictionary with the old values, then update the values in the new price object
        // If oldPrice = {"a": 1, "b": 2} and price = {"b": 3, "c": 4} then new will be {"a": 1, "b": 3, "c": 4}
        let categories = state.menus[menu_id].categories
        categories[category._id] = {
            ...categories[category._id],
            ...category
        }

        // TODO: remove when sub_categories will contain reference and not actual category
        let subCategoryIndex = state.menus[menu_id].menu.sub_categories.findIndex(x => x.food_category_id == category._id);
        state.menus[menu_id].menu.sub_categories[subCategoryIndex] = {
            ...state.menus[menu_id].menu.sub_categories[subCategoryIndex],
            category
        }

        state.menus = {...state.menus};

        if (state.current_menu_id == menu_id) {
            state.current_menu = state.menus[menu_id]
            state.current_menu_menu = state.menus[menu_id].menu
        }
    },
    DELETE_CATEGORY: (state, {menu_id, category_id}) => {
        delete state.menus[menu_id].categories[category_id]

        let subCategoryIndex = state.menus[menu_id].menu.sub_categories.findIndex(x => x.food_category_id == category_id);
        state.menus[menu_id].menu.sub_categories.splice(subCategoryIndex, 1)

        if (state.current_menu_id == menu_id) {
            state.current_menu = state.menus[menu_id]
            state.current_menu_menu = state.menus[menu_id].menu
        }
    },

    // Item
    ADD_ITEM: (state, {menu_id, item}) => {
        state.menus[menu_id].items[item._id] = item;
        state.menus[menu_id].categories[item.category_id].items.push(item._id);
        state.menus = {...state.menus};
    },
    UPDATE_ITEM: (state, {menu_id, item}) => {
        // Create a new dictionary with the old values, then update the values in the new price object
        // If oldPrice = {"a": 1, "b": 2} and price = {"b": 3, "c": 4} then new will be {"a": 1, "b": 3, "c": 4}
        let items = state.menus[menu_id].items
        items[item._id] = {
            ...items[item._id],
            ...item
        }
        state.menus = {...state.menus};

        if (state.current_menu_id == menu_id) {
            state.current_menu = state.menus[menu_id]
            state.current_menu_menu = state.menus[menu_id].menu
        }
    },
    DELETE_ITEM: (state, {menu_id, item_id}) => {
        delete state.menus[menu_id].items[item_id]

        // For each category, check if there is the item's reference and delete it
        for (const category of Object.values(state.menus[menu_id].categories)) {
            let itemIndex = category.items.findIndex(x => x == item_id);
            if (itemIndex >= 0) {
                category.items.splice(itemIndex, 1)
            }
        }
        state.menus = {...state.menus};

        if (state.current_menu_id == menu_id) {
            state.current_menu = state.menus[menu_id]
            state.current_menu_menu = state.menus[menu_id].menu
        }
    },

    // Price
    ADD_PRICE: (state, {menu_id, item_id, price}) => {
        state.menus[menu_id].items[item_id].prices.push(price);
        state.menus = {...state.menus};
    },
    UPDATE_PRICE: (state, {menu_id, item_id, price}) => {
        let itemPrices = state.menus[menu_id].items[item_id].prices;
        let priceIndex = itemPrices.findIndex(x => x._id == price._id);
        let oldPrice = itemPrices[priceIndex];

        // Create a new dictionary with the old values, then update the values in the new price object
        // If oldPrice = {"a": 1, "b": 2} and price = {"b": 3, "c": 4} then new will be {"a": 1, "b": 3, "c": 4}
        itemPrices[priceIndex].name = {
            ...itemPrices[priceIndex].name,
            ...price.name
        }
        itemPrices[priceIndex] = {
            ...oldPrice,
            ...price
        }
        state.menus = {...state.menus};

        if (state.current_menu_id == menu_id) {
            state.current_menu = state.menus[menu_id]
            state.current_menu_menu = state.menus[menu_id].menu
        }
    },
    DELETE_PRICE: (state, {menu_id, item_id, price_id}) => {
        let priceIndex = state.menus[menu_id].items[item_id].prices.findIndex(price => price._id == price_id);
        state.menus[menu_id].items[item_id].prices.splice(priceIndex, 1)
        state.menus = {...state.menus};

        if (state.current_menu_id == menu_id) {
            state.current_menu = state.menus[menu_id]
            state.current_menu_menu = state.menus[menu_id].menu
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}