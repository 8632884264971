<template>
    <tr v-if="category_id && sorting_position !== undefined">
        <td>
            <mdb-btn-group size="sm" v-if="getCategory({menu_id: menu_id, category_id: category_id}).items.length > 1">
                <mdb-btn color="white" class="mb-0"
                    :class="{ 'disabled' : sorting_position == 0}"
                    @click="moveItem($event, sorting_position, sorting_position - 1)">
                    <i class="fas fa-arrow-up"></i>
                </mdb-btn>
                <mdb-btn color="white" class="mb-0"
                    :class="{ 'disabled' : sorting_position == getCategory({menu_id: menu_id, category_id: category_id}).items.length - 1}"
                    @click="moveItem($event, sorting_position, sorting_position + 1)">
                    <i class="fas fa-arrow-down"></i>
                </mdb-btn>
            </mdb-btn-group>
        </td>
        <th scope="row" class="text-center align-middle p-0">
            <img v-if="getItem({menu_id:menu_id, item_id:item_id}).image" :src="$static_hostname + '/images/' + getItem({menu_id:menu_id, item_id:item_id}).image" style="max-width:60px;max-height:70px" />
            </th>
        <td colspan="3">
            <span v-if="getItem({menu_id:menu_id, item_id:item_id}).show == false"><span class="badge badge-pill badge-dark"><i class="fas fa-eye-slash"></i> Questo elemento è nascosto</span><br /></span>
            <b>{{ getStringFromMultilangObject(getItem({menu_id:menu_id, item_id:item_id}).name) }}</b><br />
            {{ getStringFromMultilangObject(getItem({menu_id:menu_id, item_id:item_id}).description) }}
        </td>
        <td class="text-right">
        <mdb-btn-group>
            <mdb-btn color="white" size="sm" @click="openModalItemEdit(item_id)">
                <i class="fas fa-pencil-alt"></i>
            </mdb-btn>
            <mdb-btn color="danger" size="sm" @click="setShowModalItemDelete(true); setModalItemDeleteId(item_id)">
                <i class="fas fa-trash-alt"></i>
            </mdb-btn>
        </mdb-btn-group>
        </td>
    </tr>
    <div v-else>
        <div class="row py-1">
            <div class="col-lg-1 col-sm-2">
                <img v-if="getItem({menu_id:menu_id, item_id:item_id}).image" :src="$static_hostname + '/images/' + getItem({menu_id:menu_id, item_id:item_id}).image" style="max-width:100%;max-height:100px" />
            </div>
            <div class="col-lg-9 col-md-7 col-sm-10">
                <span v-if="getItem({menu_id:menu_id, item_id:item_id}).show == false"><span class="badge badge-pill badge-dark"><i class="fas fa-eye-slash"></i> Questo elemento è nascosto</span><br /></span>
                <b>{{ getStringFromMultilangObject(getItem({menu_id:menu_id, item_id:item_id}).name) }}</b><br />
                <div class="text-truncate">{{ getStringFromMultilangObject(getItem({menu_id:menu_id, item_id:item_id}).description) }}</div>
                <span
                    v-for="category in getItemCategories({menu_id:menu_id, item_id:item_id})"
                    :key="category._id"
                    class="badge badge-light"
                >{{category.name.it_it}}</span>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12 text-right">
                <mdb-btn-group>
                    <mdb-btn color="white" size="sm" @click="openModalItemEdit(item_id)">
                        <i class="fas fa-pencil-alt"></i>
                    </mdb-btn>
                    <mdb-btn color="danger" size="sm" @click="setShowModalItemDelete(true); setModalItemDeleteId(item_id)">
                        <i class="fas fa-trash-alt"></i>
                    </mdb-btn>
                </mdb-btn-group>
            </div>
        </div>
        <MenuPrice
            v-for="price in getItem({menu_id:menu_id, item_id:item_id}).prices"
            :key="price._id"

            :menu_id="menu_id"
            :item_id="item_id"
            :price_id="price._id"
            :price="price" />
        <hr />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { getSortingPosition } from "../../../constants";
import { mdbBtn, mdbBtnGroup } from 'mdbvue';
import MenuPrice from "./MenuPrice";

export default {
  props: ['menu_id', 'category_id', 'item_id', 'sorting_position'],
  name: "MenuItem",
    components: {
      mdbBtn,
      mdbBtnGroup,
      MenuPrice
    },
  computed: mapGetters(['getStringFromMultilangObject', 'getCurrentMenuMenu', 'getCategory', 'getItem', 'getItemCategories']),
  methods: {
    ...mapActions([
        'setShowModalItemDelete', 'setModalItemDeleteId',
        'setShowModalItemUpdate', 'setModalItemUpdateId',
        'updateItemsSortingPosition']),
    openModalItemEdit(id) {
        this.setModalItemUpdateId(id);
        this.setShowModalItemUpdate(true);
    },
    openModalItemDelete(id) {
        this.setModalItemDeleteId(id);
        this.setShowModalItemDelete(true);
    },
    moveItem: function (event, old_index, new_index) {
        event.target.disabled = true;

        let { finalArray, sortingPositions } = getSortingPosition(
            this.getCategory({ menu_id: this.menu_id, category_id: this.category_id }).items,
            old_index,
            new_index,
            (x => x)
        );

        this.updateItemsSortingPosition({
            menu_id: this.menu_id,
            category_id: this.category_id,
            sortingPositions,
            finalArray,
        })
            .then((res) => {
                event.target.disabled = false;
                if (res.status != 200) {
                    alert("Error");
                }
            })
            .catch((err) => {
                event.target.disabled = false;
                alert(err);
            });
    },
  }
};
</script>