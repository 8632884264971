<template>
  <mdb-card>
    <mdb-card-header>Prenotazioni future (ultimo aggiornamento {{ lastUpdateTimeString }} - <a @click="fetchReservationForms">aggiorna adesso</a>)</mdb-card-header>
    <mdb-card-body>
      <mdb-row>
        <mdb-col col="12">
          
          <mdb-card class="w-100 mb-3" v-for="reservationForm in getReservationForms" :key="'reservationForm-'+reservationForm._id+'-reservations'">
            <mdb-card-header>
                <h5 class="text-truncate">{{ reservationForm.name }}</h5>
              </mdb-card-header>
            <mdb-card-body>
              <div v-if="reservationForm.next_reservations.length == 0" class="alert alert-info">Al momento non sono presenti prenotazioni future</div>
              <div v-else class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Orario</th>
                      <th scope="col">Pax</th>
                      <th scope="col">Dati del cliente</th>
                      <th scope="col">Note</th>
                      <th scope="col">Prenotazione effettuata il</th>
                    </tr>
                  </thead>
                  
                  <tbody
                    v-for="[date, reservations] in Object.entries(getSortedReservationsByDate(reservationForm.next_reservations))"
                    :key="'reservations-tbody-'+date"
                  >
                    <tr>
                      <td colspan="7" class="font-weight-bold">
                        {{ date.split("-").reverse().join("/") }}: totale {{ getTotalPaxFromReservationsArray(reservations) }} pax
                      </td>
                    </tr>
                    <ReservationTr
                      v-for="reservation in reservations"
                      :key="'reservation-tr-'+reservation._id"
                      :reservation="reservation"
                    />
                  </tbody>
                </table>
              </div>
            </mdb-card-body>
          </mdb-card>
          <template v-for="reservationForm in getReservationForms">
            <mdb-col xl="4" lg="6" md="12" sm="12" class="mb-4" v-for="reservation in reservationForm.next_reservations" v-bind:key="'nextReservations-reservation-' + reservation._id">
            </mdb-col>
          </template>
        </mdb-col>
      </mdb-row>
    </mdb-card-body>
  </mdb-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardHeader } from 'mdbvue';
import ReservationTr from './ReservationTr'

export default {
    name: "NextReservations",
    components: {
      ReservationTr,

      mdbRow, mdbCol,
      mdbCard, mdbCardBody, mdbCardHeader,
    },
    data() {
        return {
          fetchReservationFormsIntervalId: null
        }
    },
    created() {
      this.fetchReservationFormsIntervalId = setInterval(this.fetchReservationForms, 60 * 1000);
    },
    destroyed() {
      clearInterval(this.fetchReservationFormsIntervalId);
    },
    computed: {
      ...mapGetters(['getReservationForms', 'getLastUpdateReservationForms']),
      lastUpdateTimeString: function() {
        if (!this.getLastUpdateReservationForms) return "N/D";

        let hour = this.getLastUpdateReservationForms.getHours().toString().padStart(2, '0');
        let minute = this.getLastUpdateReservationForms.getMinutes().toString().padStart(2, '0');
        let second = this.getLastUpdateReservationForms.getSeconds().toString().padStart(2, '0');
        return `${hour}:${minute}:${second}`;
      }
    },
    methods: {
      ...mapActions(['fetchReservationForms']),
      getSortedReservationsByDate: function (reservations) {
        let reservationsByDate = {};

        let uniqueDates = [...new Set(reservations.map(r => r.datetime.slice(0, 10)))]
        uniqueDates.sort();

        for (let date of uniqueDates) {
          let reservationsCurrentDate = reservations.filter(r => (r.datetime.slice(0, 10) == date))
          reservationsCurrentDate.sort((a, b) => (a.datetime.localeCompare(b.datetime))) // Sort by time

          reservationsByDate[date] = reservationsCurrentDate;
        }

        return reservationsByDate;
      },
      getTotalPaxFromReservationsArray: function(reservations) {
        return reservations.reduce((currentTotal, reservation) => (currentTotal + reservation.number_of_people), 0);
      }
    }
};
</script>