<template>
  <div class="container">
    <nav class="tabbable">
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <router-link to="/reservation" class="nav-link" :class="($route.name == 'ReservationDashboard') ? 'active' : ''">
          Prenotazioni
        </router-link>
        <router-link to="/reservation/forms" class="nav-link" :class="($route.name == 'ReservationReservationForms') ? 'active' : ''">
          Gestione moduli
        </router-link>
        <router-link to="/reservation/form" class="nav-link" :class="($route.name == 'ReservationReservationForm' && !$route.params.reservation_form_url) ? 'active' : ''">
          Crea nuovo modulo
        </router-link>
      </div>
    </nav>
    <div class="card card-body" v-if="loading">
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Reservation',
  data() {
    return {
      loading: false
    }
  },
  async created() {
    if (Object.keys(this.getReservationForms).length == 0) {
      this.loading = true;
    }
    await Promise.all([this.fetchUserToken(), this.fetchReservationForms()]);
    this.loading = false;
  },
  computed: mapGetters(['getReservationForms']),
  methods: mapActions(['fetchUserToken', 'fetchReservationForms']),
}
</script>
