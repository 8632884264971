import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/css/mdb.min.css'
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import { LOCALHOST } from './constants'

Vue.prototype.$api_hostname = (LOCALHOST) ? 'http://localhost:3000' : 'https://easymenu-cloud-api.herokuapp.com'
Vue.prototype.$static_hostname = 'https://static.easymenu.cloud'
Vue.prototype.$public_menu_hostname = 'https://menu.easymenu.cloud'
Vue.prototype.$public_reservation_hostname = 'https://reservation.easymenu.cloud'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
